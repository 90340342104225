@import 'src/scss/base/index';

.input-error {
  $_color-error: $color-accent-red-100;

  align-items: center;
  color: $_color-error;
  display: flex;
  fill: $_color-error;
  font-size: $font-size-s;
  margin-top: $gutter-base;

  &__text {
    @include prevent-text-flicker;

    display: flex;
    flex: 1;
    margin-left: $gutter-s;
  }
}
