@import 'src/scss/base/index';

.find-assessments {
  display: flex;
  flex-direction: column;
  height: 25.2rem;
  overflow-y: auto;

  &__file {
    @include file-info;

    align-items: center;
    border: 0.1rem solid $color-file-transfer-border;
    display: flex;
  }

  &__file-text {
    @include file-transfer-text;

    &--secondary {
      @include file-transfer-text-secondary;
    }
  }

  &__file-text-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }

  &__upload-text {
    color: $color-text-light-bg-200;
    margin: 0 0 $gutter-base;
  }
}
