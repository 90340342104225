@import 'src/scss/base/index';

.import-export-modal {
  background-color: $color-blue-700 !important;
  color: $color-white !important;

  .ts-modal__header-content {
    color: $color-white;
  }

  .ts-modal__js-close-btn {
    color: $color-white;
  }

  .ts-modal__footer {
    border-top: 1px solid $color-blue-900-40;
  }
}
