@mixin file-info {
  background-color: transparent;
  border-radius: $border-radius-base;
  box-sizing: border-box;
  margin: $gutter-base 0;
  padding: 1.4rem 1.2rem;
  width: 100%;
}

@mixin file-transfer-text {
  color: $color-file-transfer;
  font-weight: $font-weight-medium;
  margin: 0;
}

@mixin file-transfer-text-secondary {
  color: $color-file-transfer-secondary;
  font-size: $font-size-s;
}
