$_color-tab-bg-focus: $color-blue-600;
$_color-tab-bg-selected: $color-blue-400;
$_color-inline-tab-bg-selected: $color-blue-200;
$_color-inline-tab-bg-focus: $color-blue-600;
$_color-inline-tab-selected: $color-black;
$_color-inline-tab-focus: $color-white;

@mixin button-tab($pagination: false) {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: $border-radius-base;
  color: $color-text-dark-bg-300;
  display: flex;
  fill: $color-text-dark-bg-300;
  font-weight: $font-weight-medium;
  height: 3.2rem;
  justify-content: center;
  outline: none;
  padding: 0 $gutter-m;
  text-decoration: none;
  transition:
    background-color $transition-base,
    color $transition-base;

  @if $pagination {
    padding: 0;
    width: $width-pagination-btn;
  }
}

@mixin button-tab-active {
  background-color: $_color-tab-bg-selected;
  color: $color-text-dark-bg;
}

@mixin button-tab-disabled {
  color: $color-misc-disabled;
  pointer-events: none;
}

@mixin button-tab-focus {
  background-color: $_color-tab-bg-focus;
  color: $color-text-dark-bg;
  cursor: pointer;
}

@mixin button-tab-inline-active {
  background-color: $_color-inline-tab-bg-selected;
  color: $_color-inline-tab-selected;
}

@mixin button-tab-inline-focus {
  background-color: $_color-inline-tab-bg-focus;
  color: $_color-inline-tab-focus;
  cursor: pointer;
}
