$_color-field-bg: $color-blue-900-5;
$_color-field-bg-disabled: $color-blue-900-20;
$_color-field: $color-black;
$_color-label: $color-black-600;
$_color-focus-ring: $color-accent-blue-100-20;
$_color-input-border-hover: $color-accent-blue-100;
$_color-input-background: $color-blue-900-5;
$_color-input-background-solid: $color-blue-900-5-solid;
$_color-input-border: $color-gray-250;
$_color-input-dark-background: $color-blue-600;
$_color-input-dark-disabled-background: $color-blue-800;
$_color-input-dark-border-focus: $color-accent-blue-100;
$_color-input-light-readonly: $color-blue-900-70;
$_color-error-hover-box-shadow: $color-accent-red-100-20;
$_color-error-border: $color-accent-red-100;
$_color-required-marker: $color-accent-red-100;
$_color-required-marker-light: $color-blue-900-50;
$_color-requires-marker-dark: $color-blue-400;

$_box-shadow-size-webkit: 0 0 0 10rem;

@mixin label-active {
  font-size: $font-size-s;
  line-height: $font-size-s;
  top: $gutter-base;
  transform: none;
}

@mixin label-position {
  left: $gutter-l;
  top: 50%;
  transform: translateY(-50%);
}

@mixin label-font-size($add-color: false) {
  align-items: center;
  display: flex;
  font-size: $font-size-base;
  line-height: 1.4rem;

  @if $add-color {
    color: $_color-label;
  }
}

@mixin input-size {
  height: 4.8rem;
  padding: $gutter-xl $gutter-l $gutter-base $gutter-l;
}

@mixin input-general {
  border-radius: $border-radius-base;
  outline: none;
  position: relative;
  transition:
    background-color $transition-base,
    border-color $transition-base,
    box-shadow $transition-base;
}

@mixin input-required($is-light: true) {
  &--required {
    &::after {
      align-self: flex-start;
      background: $_color-required-marker;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: $gutter-s;
      margin-left: $gutter-xs;
      width: $gutter-s;
    }
  }

  &--has-value {
    &::after {
      @if ($is-light) {
        background: $_color-required-marker-light;
      } @else {
        background: $_color-requires-marker-dark;
      }
    }
  }
}

@mixin input-dark {
  @include input-general;
  @include autofill-reset-dark;

  background-color: $_color-input-dark-background;
  border: $border-width-s solid $_color-input-dark-background;
  color: $color-white;

  &--is-focused,
  &:focus,
  &:hover {
    @include input-focus;
  }

  &--error,
  &.is-invalid {
    @include autofill-reset-dark-error;

    border-color: $_color-error-border;

    &:hover,
    &:focus {
      border-color: $_color-error-border;
      box-shadow: 0 0 0 $gutter-s $_color-error-hover-box-shadow;
    }
  }

  &:disabled {
    @include input-dark-disabled;
  }

  &--readonly {
    background: transparent;
    border: $border-width-s solid transparent;

    &--is-focused,
    &:focus,
    &:hover {
      border: $border-width-s solid transparent;
      box-shadow: none;
    }
  }
}

@mixin input-light {
  @include input-general;
  @include autofill-reset-light;

  background-color: $_color-input-background;
  border: $border-width-s solid $_color-input-border;
  color: $_color-field;

  &:focus,
  &:hover {
    @include input-focus;

    border: $border-width-s solid $_color-input-border-hover;
  }

  &:disabled {
    @include input-light-disabled;
  }

  &--error,
  &.is-invalid {
    border-color: $_color-error-border;

    &:hover,
    &:focus {
      border-color: $_color-error-border;
      box-shadow: 0 0 0 $gutter-s $_color-error-hover-box-shadow;
    }
  }

  &--readonly {
    color: $_color-input-light-readonly;
  }
}

@mixin input-box-shadow {
  box-shadow: 0 0 0 $gutter-s $_color-focus-ring;
}

@mixin input-focus {
  @include input-box-shadow;

  border: $border-width-s solid $_color-input-border-hover;
}

@mixin input-light-disabled {
  background-color: $_color-field-bg-disabled;
  color: $_color-label;
}

@mixin input-dark-disabled {
  background-color: $_color-input-dark-disabled-background;
  border: $border-width-s solid $_color-input-dark-disabled-background;
}

@mixin number-reset {
  appearance: none;
}

@mixin input-base {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: $gutter-base;
  position: relative;

  * {
    box-sizing: border-box;
  }

  /* stylelint-disable */
  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
  /* stylelint-enable */
}

@mixin autofill-reset-dark {
  &:-webkit-autofill {
    border: 1px solid $_color-input-dark-background;
    box-shadow: $_box-shadow-size-webkit $_color-input-dark-background inset;
    -webkit-text-fill-color: $color-white;

    &:focus,
    &:hover {
      box-shadow: $_box-shadow-size-webkit $_color-input-dark-background inset;
    }
  }
}

@mixin autofill-reset-light {
  &:-webkit-autofill {
    border: 1px solid $_color-input-background-solid;
    box-shadow: $_box-shadow-size-webkit $_color-input-background-solid inset;
    -webkit-text-fill-color: $_color-field;

    &:focus,
    &:hover {
      box-shadow: $_box-shadow-size-webkit $_color-input-background-solid inset;
    }
  }
}

@mixin autofill-reset-dark-error {
  &:-webkit-autofill {
    border: $border-width-s solid $_color-error-border;
  }
}
