$_color-icon: $color-gray-200;
$_color-icon-bg: $color-white;
$_color-icon-box-shadow: $color-gray-300-30;
$_color-notification-bg-focus: $color-black-100;

@mixin assessment-notification {
  display: flex;
  margin-left: -$gutter-base;
  position: relative;

  &:last-of-type {
    margin-bottom: $gutter-base;
  }
}

@mixin assessment-notification-button-wrapper {
  align-items: center;
  border: $border-width-s solid $_color-icon;
  border-radius: 50%;
  display: flex;
  height: $size-btn-icon;
  justify-content: center;
  position: absolute;
  right: $gutter-base;
  top: 50%;
  transform: translateY(-50%);
  width: $size-btn-icon;
}

@mixin assessment-notification-button-icon {
  background-color: $_color-icon-bg;

  &:focus,
  &:hover {
    box-shadow: $box-shadow-size $_color-icon-box-shadow;
  }

  &:disabled {
    fill: $_color-icon;
    pointer-events: none;
  }
}

@mixin assessment-notification-details {
  display: inline-block;
  width: 28.8rem;
}

@mixin assessment-notification-details-button {
  background-color: transparent;
  border: 0;
  border-radius: $border-radius-base;
  display: flex;
  flex-direction: column;
  margin-bottom: $gutter-xs;
  outline: none;
  padding: $gutter-base;
  text-align: left;
  transition: background-color $transition-base;
  width: 100%;

  &:focus,
  &:hover {
    background-color: $_color-notification-bg-focus;
    cursor: pointer;
    outline: $border-width-base solid transparent;
    outline-offset: -$border-width-base;
  }
}

@mixin assessment-notification-time {
  color: $color-text-light-bg-200;
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  padding: $gutter-s 0;
}
