@import 'src/scss/base/index';

.assessment-status-changed-notification {
  @include assessment-notification;
  $_this: &;

  &__button-wrapper {
    @include assessment-notification-button-wrapper;

    #{$_this}__btn-icon {
      @include assessment-notification-button-icon;
    }
  }

  &__details {
    @include assessment-notification-details;
  }

  &__details-button {
    @include assessment-notification-details-button;
  }

  &__time {
    @include assessment-notification-time;
  }
}
