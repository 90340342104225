@import 'src/scss/base/index';

.assessment-users-table {
  $_color-base-level-row-bg: $color-blue-700-50;
  $_color-group-border-separator: $color-white-700;
  $_color-no-rows-text: $color-white-300;
  $_color-border: $color-white-900;
  $_this: &;

  border: $border-width-s solid $_color-border;
  margin: $gutter-l 0;

  .ag-root {
    flex: unset;
    overflow: unset;
    width: 100%;
  }

  .ag-row {
    &:nth-of-type(2n-1) {
      background: $_color-base-level-row-bg;
    }
  }

  .ag-cell {
    line-height: 2.4;
    padding-top: $gutter-s;

    &.ag-cell-wrap-text {
      overflow-wrap: break-word;
      text-overflow: initial;
    }
  }

  .ag-header {
    border-bottom-color: $_color-border;
  }

  &__button {
    margin: $gutter-l 0;
  }

  #{$_this}__row {
    &:last-child {
      border-bottom: 0;
    }

    &--last-in-group {
      border-top-color: $_color-group-border-separator;
    }

    &.ag-row-last {
      border-bottom: 0;
    }
  }

  .ag-react-container {
    align-items: center;
    display: flex;
  }

  &__name-cell,
  &__name-header-cell {
    @include catalog-name-cell-text;
  }

  &__name-cell {
    &.ag-cell {
      @include catalog-name-cell-size;
    }
  }

  #{$_this}__contact-cell {
    font-family: $font-primary;
    font-weight: $font-weight-bold;
  }

  .ag-overlay {
    .ag-overlay-no-rows-wrapper {
      color: $_color-no-rows-text;
      padding-top: 4.1rem;
    }
  }
}
