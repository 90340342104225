@import 'src/scss/base/index';

.spinner {
  $_color-spinner-circle: $color-black;
  $_color-spinner-indicator: $color-accent-green-150;
  $_color-spinner-circle-light: $color-black-200;
  $_color-spinner-indicator-light: $color-accent-orange;

  $_size-spinner: 1.8rem;
  $_size-spinner-l: 9.2rem;

  $_this: &;
  animation: spin 1.2s infinite linear;
  height: $_size-spinner;
  stroke-width: 2;
  width: $_size-spinner;

  &__circle {
    stroke: $_color-spinner-circle;
  }

  &__indicator {
    stroke: $_color-spinner-indicator;
    stroke-dasharray: 16 34;
    stroke-dashoffset: 20;
    stroke-linecap: round;
  }

  &--light {
    #{$_this}__circle {
      stroke: $_color-spinner-circle-light;
    }

    #{$_this}__indicator {
      stroke: $_color-spinner-indicator-light;
    }
  }

  &--large {
    height: $_size-spinner-l;
    stroke-width: 1;
    width: $_size-spinner-l;
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
