.ts-datepicker,
.DateRangePicker_picker {
  .CalendarDay {
    $_color-border-day-focus: $color-accent-blue-100;
    $_color-border-today: $color-blue-200;
    $_color-box-shadow-day-focus: $color-accent-blue-100-20;
    $_color-day: $color-blue-600;
    $_color-day-outside: $color-blue-600-70;
    $_color-day-range-bg: $color-accent-blue-100-20;
    $_color-day-selected-bg: $color-accent-blue-100;

    border-color: transparent;
    border-radius: $border-radius-s;
    color: $_color-day;
    font-size: $font-size-s;
    outline: none;
    padding: 0;
    position: relative;
    transition: background-color $transition-base;

    &:focus,
    &:hover {
      background-color: transparent;
      border: $border-width-s solid $_color-border-day-focus;
      box-shadow: 0 0 0 0.4rem $_color-box-shadow-day-focus;
      color: $_color-day;
    }

    &__hovered,
    &__selected {
      &_span {
        background-color: $_color-day-range-bg;

        &:focus,
        &:hover {
          background-color: $_color-day-range-bg;
          box-shadow: none;
        }
      }
    }

    &__today {
      border-color: $_color-border-today;
    }

    &__outside {
      color: $_color-day-outside;
      pointer-events: none;
    }

    &__selected {
      background-color: $_color-day-selected-bg;
    }
  }

  .CalendarMonth {
    padding: 0 !important;

    &_caption {
      color: $color-text-light-bg;
      font-size: $font-size-base;
    }

    &_table {
      border-collapse: separate;
      border-spacing: $gutter-s;
    }
  }

  .CalendarMonthGrid {
    &__horizontal {
      left: $gutter-base;
    }
  }

  .DateInput {
    &_input {
      &::-ms-clear {
        display: none;
      }
    }

    &_fang {
      display: none;
    }
  }

  .DateRangePicker,
  .SingleDatePicker {
    &_picker {
      background-color: transparent;
      top: 100% !important;
    }
  }

  .DayPicker {
    $_color-box-shadow: $color-black-200;

    animation: open-table-dropdown-menu $transition-base forwards;
    padding-bottom: $gutter-base;
    transform-origin: left top;
    width: calc(100% + #{$gutter-xs}) !important;

    &_transitionContainer,
    &__withBorder {
      border-radius: $border-radius-base;
    }

    &_weekHeader {
      margin: 0 0 $gutter-base;
      padding: 0 !important;

      &_li {
        margin: 0 $gutter-xs;
      }

      &_ul {
        margin: -$gutter-base 0;
        padding-left: 0.1rem;
      }
    }

    &__withBorder {
      box-shadow: 0 0.4rem 1.6rem 0.4rem $_color-box-shadow;
    }

    > div {
      > div {
        width: 100% !important;
      }
    }
  }

  .DayPickerNavigation {
    $_color-arrow: $color-black-800;
    $_color-arrow-active-bg: $color-black-200;
    $_color-arrow-focus-bg: $color-black-100;
    $_color-box-shadow-arrow-focus: $color-black-50;

    $_size-arrow: 0.6rem;
    $_position-arrow: $_size-arrow * 0.5;
    $_size-btn: 3.2rem;

    &_button {
      border: 0;
      border-radius: $border-radius-s;
      height: $_size-btn;
      outline: none;
      padding: 0;
      top: 1.4rem;
      transition:
        background-color $transition-base,
        box-shadow $transition-base;
      width: $_size-btn;

      &::before {
        border: $_size-arrow solid transparent;
        border-radius: 0.1rem;
        content: '';
        height: 0;
        left: $_size-btn * 0.5;
        position: absolute;
        top: $_size-btn * 0.5;
        width: 0;
      }

      &:first-of-type {
        left: 0.8rem;

        &::before {
          border-right-color: $_color-arrow;
          transform: translateX(-50%) translateX(-#{$_position-arrow})
            translateY(-50%);
        }
      }

      &:last-of-type {
        right: 0.8rem;

        &::before {
          border-left-color: $_color-arrow;
          transform: translateX(-50%) translateX(#{$_position-arrow})
            translateY(-50%);
        }
      }

      &:focus,
      &:hover {
        background-color: $_color-arrow-focus-bg;
        border: 0;
        box-shadow: 0 0 0 0.4rem $_color-box-shadow-arrow-focus;
      }

      &:active {
        background-color: $_color-arrow-active-bg;
        box-shadow: none;
      }
    }

    &_svg {
      &__horizontal {
        display: none;
      }
    }
  }
}

body > div > .DateRangePicker_picker {
  @include z-index('modal', 'portal');
  background-color: transparent;
  opacity: 0;
  transition: opacity $transition-quick;

  > .DayPicker {
    margin-top: -(2rem - $gutter-s);
  }
}
