@import 'src/scss/base/index';

.selected-assessments-types-counter {
  $_color-divider-border: $color-blue-700-50;
  $_color-text: $color-blue-600;

  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0;
  padding: 0;

  &__assessment-counter {
    color: $_color-text;
    font-weight: $font-weight-medium;
    padding: 0 1.4rem;
    position: relative;

    &:not(:last-child) {
      &::after {
        background-color: $_color-divider-border;
        content: '';
        height: 1.1rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: $border-width-base;
      }
    }

    &:last-child {
      border: 0;
      padding-right: 0;
    }
  }
}
