@import 'src/scss/base/index';

.btn-icon {
  $_color-bg-focus: $color-black-100;

  $_size-icon: 3.2rem;

  $_this: &;

  background-color: transparent;
  border: 0;
  border-radius: 50%;
  box-sizing: border-box;
  height: $_size-icon;
  outline: none;
  padding: $gutter-s;
  transition:
    background-color $transition-base,
    box-shadow $transition-base,
    fill $transition-base;
  width: $_size-icon;

  &:focus,
  &:hover,
  &--active {
    @include button-icon-color-bg(true);
    cursor: pointer;

    #{$_this}__tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  &:active {
    background-color: inherit;
  }

  &__content {
    display: flex;
  }

  &--has-tooltip {
    position: relative;
  }

  &--reset {
    $_color-button-bg-hover: $color-white-800;
    $_color-icon-default: $color-blue-500;
    $_color-icon-hover: $color-accent-red-100;

    align-items: center;
    display: flex;
    fill: $_color-icon-default;
    height: 3.2rem;
    justify-content: center;
    width: 3.2rem;

    &:focus,
    &:hover {
      background-color: $_color-button-bg-hover;
      fill: $_color-icon-hover;
    }
  }
}
