@import 'src/scss/base/index';

.loader {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__spinner {
    margin: $gutter-xxxl 0 $gutter-l 0;
  }
}
