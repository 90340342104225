@import 'src/scss/base/index';

.upload-error {
  $_color-accent: $color-gray-300;

  color: $color-text-light-bg-200;
  display: flex;
  flex-direction: column;
  margin-bottom: $gutter-l;
  overflow-y: auto;

  &__btn {
    align-self: flex-start;
    color: $color-text-light-bg-200;
    font-weight: $font-weight-regular;
    margin: 0 0 $gutter-base;

    &::after {
      background-color: $_color-accent;
      height: $border-width-s;
    }
  }

  &__icon {
    fill: $color-text-light-bg-200;
  }

  &__list {
    font-size: $font-size-m;
    margin: 0;
    padding: 0 0 0 $gutter-xl;
  }

  &__list-item {
    line-height: 1.2;

    &:not(:last-of-type) {
      margin-bottom: $gutter-s;
    }
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
  }
}
