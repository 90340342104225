// Checkbox / radio styles
@mixin checkbox-radio-focus-circle($is-dark: false) {
  $_color-bg: $color-white-800;
  $_color-bg-dark: $color-black-100;

  background-color: $_color-bg;
  border-radius: 50%;
  box-sizing: border-box;
  content: '';
  height: $size-check-radio-base-bg;
  opacity: 0;
  position: absolute;
  transition: opacity $transition-base;
  width: $size-check-radio-base-bg;

  @if $is-dark {
    background-color: $_color-bg-dark;
  }
}

@mixin checkbox-radio-set {
  $_color-explanation: $color-white-300;
  $_color-legend: $color-white-400;
  $_color-required-indicator-w-value: $color-blue-400;

  border: 0;
  margin: 0;
  padding: 0;

  &__explanation {
    color: $_color-explanation;
    font-size: $font-size-s;
    line-height: 1.66;
    margin: 0;
    max-width: 55rem;
  }

  &__legend,
  &__options {
    margin-left: $gutter-l;
  }

  &__legend {
    @include input-required;

    color: $_color-legend;
    font-size: $font-size-s;
    margin-bottom: $gutter-l;
    position: relative;

    &::after {
      position: absolute;
    }

    &--has-value {
      &::after {
        background-color: $_color-required-indicator-w-value;
      }
    }

    &--marginless {
      margin: 0;
    }

    &--bold {
      font-weight: $font-weight-bold;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    padding-left: $gutter-s;

    &--horizontally-aligned {
      flex-direction: row;
      margin-left: 0;
      padding-left: 0;

      .radio {
        margin-right: $gutter-xl;
      }
    }
  }

  .input-error {
    margin-top: $gutter-s;
  }
}

// Basis for animated text underline
@mixin text-underline-basis {
  content: '';
  left: 0;
  position: absolute;
  top: 100%;
  transform: scaleX(0);
  transition: transform $transition-slow ease-out;
  width: 100%;
}

@mixin text-underline-focus {
  transform: scaleX(1);
}

@mixin square($size) {
  height: $size;
  width: $size;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
