@import 'src/scss/base/index';

.password-form {
  &__inputs-row {
    &:not(:last-child) {
      margin-bottom: $gutter-base;
    }
  }

  .labeled-select-input {
    margin-top: $gutter-xl;
  }

  &__validation-rule {
    display: flex;
    align-items: center;
    margin-top: $gutter-xs;
  }

  &__fulfilled {
    color: #78f1bc;
    display: flex;
    align-items: center;
  }

  &__not-fulfilled {
    color: #ff2864;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }

  &__fulfilled-text {
    backface-visibility: hidden;
    font-weight: 700;
    flex: 1 1;
    margin-left: 0.4rem;
  }

  &__error-text {
    backface-visibility: hidden;
    font-weight: 700;
    flex: 1 1;
    margin-left: 0.4rem;
  }
}
