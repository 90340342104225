@import 'src/scss/base/index';

.purchase-assessment-header {
  $_color-select-all-assessments-type: $color-blue-500;
  $_color-dropdown-box-shadow: $color-black-250;
  $_color-dropdown-border: $color-blue-900-15;
  $_color-dropdown-secondary-text: $color-blue-900-70;

  $_this: &;

  &__text {
    letter-spacing: 0.01rem;
  }

  &__set-all-assessments-types {
    margin-top: 2rem;
    position: relative;
    width: 21.1rem;

    .dropdown-menu {
      border: $border-width-s solid $_color-dropdown-border;
      box-shadow: 0 0.4rem 1.2rem $_color-dropdown-box-shadow;
      max-width: 28.1rem;
      width: 28.1rem;
    }

    #{$_this}__dropdown-text {
      &--main {
        font-weight: $font-weight-bold;
        letter-spacing: 0.1px;
        line-height: 1.15;
        margin-bottom: $gutter-base;
      }

      &--secondary {
        color: $_color-dropdown-secondary-text;
        font-size: $font-size-s;
        font-weight: $font-weight-medium;
        line-height: 1.15;
      }
    }

    .btn-link {
      &--secondary {
        &::after {
          background-color: $_color-select-all-assessments-type;
        }
      }

      &__text {
        color: $_color-select-all-assessments-type;
        font-weight: $font-weight-bold;
      }

      &__icon {
        fill: $_color-select-all-assessments-type;
      }
    }
  }
}
