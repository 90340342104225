@import 'src/scss/base/index';

.cookie-modal-content {
  $_color-content-text: $color-blue-600;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__text {
    color: $_color-content-text;
    line-height: 1.5;
    margin: 1.9rem 0 1.4rem;
    text-align: center;
    width: 36rem;
  }

  &__privacy-policy-link {
    color: $_color-content-text;
    font-weight: $font-weight-bold;
    text-decoration: none;
  }
}
