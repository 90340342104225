@import 'src/scss/base/index';

.table-toggle {
  $_color-toggle-btn: $color-black-600;
  $_color-toggle-circle: $color-accent-red-100;
  $_color-toggle-btn-checked: $color-accent-green-100-20;
  $_color-toggle-circle-checked: $color-accent-green-100;
  $_box-shadow-toggle-circle:
    0 0 0.2rem rgba($color-black, 0.12),
    0 0.2rem 0.2rem rgba($color-black, 0.24);
  $_box-shadow-toggle-circle-hover: 0 0 0 0.9rem $color-black-200;
  $_box-shadow-toggle-circle-checked-hover: 0 0 0 0.9rem $color-white-900;
  $_box-shadow-toggle-circle-focus: 0 0 0 0.9rem $color-black-600;
  $_box-shadow-toggle-circle-checked-focus: 0 0 0 0.9rem $color-white-800;
  $_color-toggle-circle-disabled: $color-blue-500;
  $_color-toggle-btn-checked-disabled: $color-white-600;

  &--dark {
    .toggle {
      &__btn {
        &::before {
          background-color: $_color-toggle-btn;
        }

        &::after {
          background-color: $_color-toggle-circle;
          box-shadow: $_box-shadow-toggle-circle;
        }

        /* stylelint-disable */
        &:hover {
          &::after {
            box-shadow: $_box-shadow-toggle-circle-hover;
          }
        }

        &:focus {
          &::after {
            box-shadow: $_box-shadow-toggle-circle-focus;
          }
        }

        &--checkbox-checked {
          &::before {
            background-color: $_color-toggle-btn-checked;
          }

          &::after {
            background-color: $_color-toggle-circle-checked;
          }

          &:hover {
            &::after {
              box-shadow: $_box-shadow-toggle-circle-checked-hover;
            }
          }

          &:focus {
            &::after {
              box-shadow: $_box-shadow-toggle-circle-checked-focus;
            }
          }
          /* stylelint-enable */
        }
      }

      &--disabled {
        .toggle__btn {
          /* stylelint-disable */
          &:not(&--checkbox-checked) {
            &::after {
              background-color: $_color-toggle-circle-disabled;
            }
          }

          &--checkbox-checked {
            &::before {
              background-color: $_color-toggle-btn-checked-disabled;
            }
          }
          /* stylelint-enable */
        }
      }
    }
  }

  .ag-react-container {
    height: 100%;
  }

  .toggle {
    height: 2.2rem;

    &__label {
      font-weight: $font-weight-regular;
      margin-left: 3.6rem;
    }

    &__hidden-input {
      &:active {
        /* stylelint-disable */
        + .toggle__btn {
          &::before {
            box-shadow: none;
          }

          &::after {
            box-shadow: $_box-shadow-toggle-circle-focus;
          }
        }

        + .toggle__btn--checkbox-checked {
          &::after {
            box-shadow: $_box-shadow-toggle-circle-checked-focus;
          }
        }
        /* stylelint-enable */
      }
    }
  }
}
