@mixin heading($heading) {
  $_font-size-h1: 2.4rem;
  $_font-size-h2: 2rem;
  $_font-size-h3: 1.8rem;
  $_font-size-h4: 1.6rem;

  font-weight: 700;
  margin: 0;

  @if $heading == 'h1' {
    font-size: $_font-size-h1;
  }
  @if $heading == 'h2' {
    font-size: $_font-size-h2;
  }
  @if $heading == 'h3' {
    font-size: $_font-size-h3;
  }
  @if $heading == 'h4' {
    font-size: $_font-size-h4;
  }
}
