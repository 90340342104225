/* stylelint-disable */
@font-face {
  font-display: swap;
  font-family: 'Roboto Mono';
  font-weight: $font-weight-regular;
  src: url('../fonts/RobotoMono-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto Mono';
  font-weight: $font-weight-light;
  src: url('../fonts/RobotoMono-Light.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-weight: $font-weight-regular;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-weight: $font-weight-medium;
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-weight: $font-weight-bold;
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}
/* stylelint-enable */
