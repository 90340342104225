@import 'src/scss/base/index';

.user-assignment {
  &__table-container {
    padding: 0 $gutter-page;
  }

  &__button {
    margin: $gutter-l 0;
  }

  &__assessment-table-title {
    margin-bottom: $gutter-l;
  }
}
