@import 'src/scss/base/index';

.header {
  $_color-dropdown-content: $color-black-800;
  $_color-dropdown-subheading: $color-black-600;
  $_color-border: $color-drawer-border;
  $_this: &;

  align-items: center;
  background-color: $color-header-bg;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  height: $height-header;

  &__actions-wrapper {
    align-items: center;
    display: flex;

    #{$_this}__icon {
      @include button-icon-header;

      margin-right: $gutter-base;

      &:focus,
      &:hover {
        @include button-icon-header-active;
        @include button-icon-header-focus;
      }
    }
  }

  &__dropdown-wrapper {
    position: relative;

    .dropdown-menu {
      @include dropdown-menu-right-aligned;

      max-width: 23rem;
      width: 23rem;
    }
  }

  &__dropdown-content {
    color: $_color-dropdown-content;
    padding: $gutter-base $gutter-l;
  }

  &__dropdown-subheading {
    border-bottom: $border-width-s solid $_color-border;
    color: $_color-dropdown-subheading;
    font-size: $font-size-s;
    margin: 0 0 $gutter-m;
    text-transform: uppercase;
  }

  &__dropdown-heading {
    line-height: 1.2;
    margin-bottom: $gutter-s;
  }

  &__dropdown-item {
    margin: $gutter-s 0;
  }

  &__dropdown-item-name {
    font-weight: $font-weight-bold;
    margin-right: $gutter-s;
  }

  &__dropdown-button {
    margin-top: $gutter-l;
    width: 100%;
  }

  &__content {
    @include z-index('header', 'content');

    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__icons-wrapper {
    display: flex;
    margin-left: $gutter-xl;
  }

  &__icon {
    @include z-index('header', 'icon');
  }

  &__frame {
    @include frame;

    padding-bottom: $gutter-page;
  }

  &__logo {
    height: 7rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
}
