@import 'src/scss/base/index';

.bulk-assessment-actions {
  $_color-btn-border: $color-white-300;

  align-items: center;
  display: flex;

  &__btn {
    margin-right: $gutter-base;

    &--border {
      border-color: $_color-btn-border;
    }

    &--large-icon {
      .btn {
        &__icon {
          margin-left: -0.6rem;
          padding-right: $gutter-xs;
        }
      }
    }
  }
}
