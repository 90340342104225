@import 'src/scss/base/index';

.select-input {
  $_color-dropodwn-shadow: $color-black-250;
  $_color-accent-active: $color-accent-blue-100;
  $_color-label-dark: $color-gray-300;
  $_color-error-hover-box-shadow: $color-accent-red-100-20;
  $_color-error-border: $color-accent-red-100;
  $_color-explanation-dark: $color-text-dark-bg-200;
  $_color-disabled-option: $color-blue-900-70;
  $_color-field-bg-disabled: $color-blue-900-20;
  $_color-dark-input-text: $color-white;
  $_color-item-label: $color-blue-900-90;
  $_color-item-info: $color-blue-900-70;

  $_this: &;
  @include input-base;

  &--medium-height {
    #{$_this}__control,
    #{$_this}__value-container {
      height: 4rem;
    }

    #{$_this}__value-container {
      &--has-value {
        padding: $gutter-l $gutter-base $gutter-s $gutter-l;
      }
    }
  }

  &--menu-placement-top {
    #{$_this}__menu {
      bottom: 100%;
      margin: 0 0 $gutter-s;
      top: auto;
      transform-origin: left bottom;
    }
  }

  &__icon {
    fill: $_color-label;
    pointer-events: none;
    transition: fill $transition-base;

    &--date {
      position: absolute;
      right: $gutter-m;
      top: 50%;
      transform: translateY(-50%);
    }

    &--active {
      fill: $_color-accent-active;
    }
  }

  #{$_this}__control {
    @include input-light;
    align-items: center;
    display: flex;
    height: 4.8rem;
    justify-content: space-between;
    width: 100%;

    &--is-focused {
      border: $border-width-s solid $_color-input-border-hover;
      box-shadow: 0 0 0 $gutter-s $_color-focus-ring;
    }

    &--is-disabled {
      @include input-light-disabled;
    }
  }

  &__indicators {
    align-items: center;
    display: flex;
  }

  &__value-container {
    @include input-size;

    align-items: center;
    display: flex;
    overflow: hidden;
    padding: $gutter-m $gutter-base $gutter-m $gutter-l;
    width: 100%;

    input {
      width: 0;
    }

    #{$_this}__placeholder {
      height: 2rem;
      margin: 0;
    }

    &--has-value {
      align-items: flex-end;
      padding: $gutter-l $gutter-base $gutter-base $gutter-l;
      position: relative;

      #{$_this}__placeholder {
        @include label-active;
        height: auto;
        margin: 0;
        position: absolute;
      }
    }
  }

  &__dropdown-indicator {
    display: flex;
    padding-right: $gutter-l;
  }

  &__placeholder,
  &__single-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__placeholder {
    @include label-font-size($add-color: true);
    @include input-required;

    pointer-events: none;
    transition: $transition-base;
  }

  &__menu {
    @include dropdown-menu;
    @include dropdown-menu-open;
    @include z-index('input-field', 'dropdown-menu');

    animation: open-table-dropdown-menu $transition-base forwards;
    box-shadow: 0 $gutter-s $gutter-m $_color-dropodwn-shadow;
    max-width: none;
  }

  &__option {
    @include dropdown-menu-item;
    text-transform: capitalize;

    &:active {
      @include dropdown-menu-item-active;
    }

    &--is-selected {
      @include dropdown-menu-item-selected;
    }

    &--is-disabled {
      color: $_color-disabled-option; // 70%;
    }

    &:focus,
    &:hover,
    &--is-focused {
      @include dropdown-menu-item-focus;
    }

    .item {
      &__label {
        color: $_color-item-label;
        font-weight: $font-weight-bold;
      }

      &__info {
        color: $_color-item-info;
        font-size: $font-size-s;
        text-transform: none;
      }
    }
  }

  &__menu-list {
    max-height: 19rem;
    overflow-y: auto;
  }

  &__single-value {
    text-transform: capitalize;
  }

  .is-right-aligned {
    #{$_this}__menu {
      @include dropdown-menu-right-aligned;
    }
  }

  &--dark {
    #{$_this}__control {
      @include input-dark;

      .option {
        color: $_color-dark-input-text;
      }
    }

    #{$_this}__label,
    #{$_this}__placeholder {
      @include input-required($is-light: false);

      color: $_color-label-dark;
    }

    #{$_this}__icon {
      fill: $_color-label-dark;
    }
  }

  &--error {
    #{$_this}__control {
      border-color: $_color-error-border;

      &:hover,
      &:focus,
      &--is-focused {
        border-color: $_color-error-border;
        box-shadow: 0 0 0 $gutter-s $_color-error-hover-box-shadow;
      }
    }
  }

  &--disabled {
    &#{$_this}--dark {
      #{$_this}__control {
        @include input-dark-disabled;
      }
    }
  }

  &--readonly {
    #{$_this}__control {
      background-color: $_color-field-bg-disabled;
    }
  }

  &__explanation {
    color: $_color-explanation-dark;
    font-size: $font-size-s;
    margin-top: $gutter-s;
  }
}
