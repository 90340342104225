@mixin drawer-header-btn {
  $_color-btn: $color-blue-900;
  $_color-btn-bg: $color-white;
  $_color-btn-border: $color-gray-200;
  $_color-btn-border-focus: $color-gray-300;
  $_color-btn-box-shadow-focus: $color-gray-300-30;

  $_size-btn-icon: 1.6rem;

  background-color: $_color-btn-bg;
  border-color: $_color-btn-border;
  color: $_color-btn;

  &:focus,
  &:hover {
    border-color: $_color-btn-border-focus;
    box-shadow: $box-shadow-size $_color-btn-box-shadow-focus;
  }

  &:active {
    border-color: $_color-btn-border;
    box-shadow: none;
  }

  &--small-icon {
    .btn__icon {
      height: $_size-btn-icon;
      width: $_size-btn-icon;
    }
  }

  &--close {
    .btn__icon {
      fill: $_color-btn;
      stroke: $_color-btn;
    }
  }
}

@mixin drawer-details {
  padding: $height-drawer-upper-controls $gutter-xl 0;
}

@mixin drawer-details-label {
  color: $color-text-light-bg-200;
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  margin: $gutter-l 0 $gutter-xs;
}

@mixin drawer-details-alias {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  margin-top: $gutter-base;
}

@mixin drawer-details-separator {
  border-top: $border-width-s solid $color-drawer-border;
  margin-top: $gutter-xl;
  padding-top: $gutter-xl;
}
