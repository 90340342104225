@import 'src/scss/base/index';

.table-dropdown {
  &__btn {
    @include heading('h1');

    color: $color-text-dark-bg;
    fill: $color-text-dark-bg;
    font-weight: $font-weight-bold;

    &::after {
      background-color: $color-text-dark-bg;
    }
  }

  &__dropdown-menu {
    min-width: 18rem;
  }
}
