.ReactModal {
  $_color-box-shadow: $color-black;
  $_color-modal-bg: $color-white;
  $_color-overlay-bg: $color-black-500;

  $_box-shadow-modal: 0 1.6rem 2.4rem 0 $_color-box-shadow;

  &__Content {
    background-color: $_color-modal-bg;
    border-radius: $border-radius-l;
    box-shadow: $_box-shadow-modal;
    box-sizing: border-box;
    color: $color-text-light-bg;
    position: absolute;
  }

  &__Overlay {
    @include z-index('modal', 'overlay');

    bottom: 0;
    background-color: $_color-overlay-bg !important;
    left: 0;
    opacity: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity $transition-base;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }
}
