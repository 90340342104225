/* stylelint-disable */
// Fonts
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Roboto Mono', monospace;
$font-login-arial: 'Arial Regular';
/* stylelint-enable */

// Breakpoints
$breakpoint-1024: 1024;
$breakpoint-768: 768;

$breakpoint-height-768: 768;

// Base
$max-width-page: 144rem;
$max-width-page-ie: 1440px;

$gutter-page: 2.4rem;

// Font sizes
$font-size-xs: 1.1rem;
$font-size-s: 1.2rem;
$font-size-m: 1.3rem;
$font-size-base: 1.4rem;
$font-size-base-percentage: 62.5%;
$font-size-l: 1.6rem;
$font-size-xl: 2rem;
$font-size-xxl: 2.4rem;

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Font styles
$font-style-italic: italic;

// Box shadow
$box-shadow-size: 0 0 0 0.4rem;

// Border radius
$border-radius-s: 0.4rem;
$border-radius-base: 0.8rem;
$border-radius-l: 1.2rem;

// Border-width
$border-width-s: 0.1rem;
$border-width-base: 0.2rem;

// Padding / margin
$gutter-xs: 0.2rem;
$gutter-s: 0.4rem;
$gutter-base: 0.8rem;
$gutter-m: 1.2rem;
$gutter-l: 1.6rem;
$gutter-xl: 2.4rem;
$gutter-xxl: 3.2rem;
$gutter-xxxl: 4rem;

// Animations / transitions
$transition-slow: 0.3s;
$transition-base: 0.2s;
$transition-quick: 0.15s;
$transition-quickest: 0.1s;
$transition-delay: 0.025s;
$transition-toast: 8s;

// Button Icon
$size-btn-icon: 3.2rem;

// Checkbox / radio
$size-check-radio-base: 1.6rem;
$size-check-radio-base-bg: 3.2rem;

// File transfer (file information)
$box-shadow-file-transfer: 0 0.2rem 0.8rem 0 $color-file-transfer-box-shadow;
$box-shadow-file-transfer-focus:
  $box-shadow-file-transfer,
  0 0 0 0.4rem $color-file-transfer-border-focus;

// Header
$height-header: 7.2rem;

// Pagination
$width-pagination-btn: 3.2rem;

// Drawer
$height-drawer-upper-controls: 6.4rem;
$width-drawer: 40rem;

// Table footer
$height-table-footer: 7.2rem;
