// Black with transparency
$color-solid-black: #000;
$color-black: #06080c;
$color-login-text-black: #1a1a1a;
$color-black-50: rgba($color-black, 0.05);
$color-black-100: rgba($color-black, 0.1);
$color-black-200: rgba($color-black, 0.2);
$color-black-250: rgba($color-black, 0.25);
$color-black-400: rgba($color-black, 0.4);
$color-black-500: rgba($color-black, 0.5);
$color-black-600: rgba($color-black, 0.6);
$color-black-700: rgba($color-black, 0.7);
$color-black-800: rgba($color-black, 0.8);

// White with transparency
$color-white: #fff;
$color-off-white: #eee;
$color-white-50: rgba($color-white, 0.05);
$color-white-100: rgba($color-white, 0.9);
$color-white-200: rgba($color-white, 0.8);
$color-white-300: rgba($color-white, 0.7);
$color-white-400: rgba($color-white, 0.6);
$color-white-500: rgba($color-white, 0.5);
$color-white-600: rgba($color-white, 0.4);
$color-white-700: rgba($color-white, 0.3);
$color-white-800: rgba($color-white, 0.2);
$color-white-900: rgba($color-white, 0.1);

// Gray
$color-gray-50: #4a4e5d;
$color-gray-100: #e7e8e9;
$color-gray-200: #dcdddf;
$color-gray-250: #f3f4f4; // $color-blue-900-5 as pure hex code
$color-gray-300: #b9babe;
$color-gray-300-30: rgba($color-gray-300, 0.3);
$color-gray-900: #8b8d94;
$color-gray-920: #74767f;
$color-gray-930: #5c5f69;
$color-gray-950: #454954; // $color-black-100 as pure hex value
$color-gray-950-60: rgba($color-gray-950, 0.6);
$color-gray-960: rgba(6, 8, 12, 0.2);

// Accent colors
$color-accent-blue-100: #03b3ff;
$color-accent-blue-150: #01a0e6;
$color-accent-blue-200: #2196f3;
$color-accent-blue-100-20: rgba($color-accent-blue-100, 0.2);
$color-accent-blue-100-30: rgba($color-accent-blue-100, 0.3);
$color-accent-blue-100-50: rgba($color-accent-blue-100, 0.5);
$color-accent-blue-100-70: rgba($color-accent-blue-100, 0.7);
$color-accent-cyan: #78f1e2;
$color-accent-green-100: #78f1bc;
$color-accent-green-100-30: rgba($color-accent-green-100, 0.3);
$color-accent-green-100-70: rgba($color-accent-green-100, 0.7);
$color-accent-green-100-20: rgba($color-accent-green-100, 0.2);
$color-accent-green-150: #4aeca5;
$color-accent-green-250: #41d192;
$color-accent-orange: #ff7902;
$color-accent-red-10: #abafb9;
$color-accent-red-50: #ff4783;
$color-accent-red-100: #ff2864;
$color-accent-red-100-15: rgba($color-accent-red-100, 0.15);
$color-accent-red-100-20-solid: #ffdbe5;
$color-accent-red-100-20: rgba($color-accent-red-100, 0.2);
$color-accent-red-100-30: rgba($color-accent-red-100, 0.3);
$color-accent-red-100-90: rgba($color-accent-red-100, 0.9);
$color-accent-red-200: #e0205a;
$color-accent-yellow-100: #fffd00;
$color-accent-yellow-100-20: rgba($color-accent-yellow-100, 0.2);
$color-accent-yellow-100-40: rgba($color-accent-yellow-100, 0.4);
$color-accent-yellow-100-70: rgba($color-accent-yellow-100, 0.7);
$color-accent-yellow-200: #ebe900;
$color-accent-yellow-200-40: rgba($color-accent-yellow-200, 0.4);

// Main color and it's shades
$color-blue-100: #e2e7f8;
$color-blue-100-60: rgba($color-blue-100, 0.6);
$color-blue-200: #b8c4e7;
$color-blue-300: #7a8ab8;
$color-blue-300-40: rgba($color-blue-300, 0.4);
$color-blue-300-90: rgba($color-blue-300, 0.9);
$color-blue-350: #5c678b;
$color-blue-390: #385261;
$color-blue-400: #4a577f;
$color-blue-400-40: rgba($color-blue-400, 0.4);
$color-blue-410: #464955;
$color-blue-500: #374262;
$color-blue-600: #2d3651;
$color-blue-600-10: rgba($color-blue-600, 0.1);
$color-blue-600-30: rgba($color-blue-600, 0.3);
$color-blue-600-50: rgba($color-blue-600, 0.5);
$color-blue-600-70: rgba($color-blue-600, 0.7);
$color-blue-600-90: rgba($color-blue-600, 0.9);
$color-blue-700: #232a3f;
$color-blue-700-10: rgba($color-blue-700, 0.1);
$color-blue-700-50: rgba($color-blue-700, 0.5);
$color-blue-800: #1d2234;
$color-blue-900: #171b29;
$color-blue-900-5-solid: #f3f3f4; // $color-blue-900-5 as pure hex value
$color-blue-900-5: rgba($color-blue-900, 0.05);
$color-blue-900-10: rgba($color-blue-900, 0.1);
$color-blue-900-15: rgba($color-blue-900, 0.15);
$color-blue-900-20: rgba($color-blue-900, 0.2);
$color-blue-900-30: rgba($color-blue-900, 0.3);
$color-blue-900-40: rgba($color-blue-900, 0.4);
$color-blue-900-50: rgba($color-blue-900, 0.5);
$color-blue-900-60: rgba($color-blue-900, 0.6);
$color-blue-900-70: rgba($color-blue-900, 0.7);
$color-blue-900-85: rgba($color-blue-900, 0.85);
$color-blue-900-90: rgba($color-blue-900, 0.9);
