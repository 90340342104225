@import 'src/scss/base/index';

.global-navigation {
  $_color-bg: $color-black;
  $_color-section-title: $color-white-300;
  $_color-link: $color-white;
  $_color-bottom-navigation-border: $color-white-800;
  $_color-close-icon-focus-bg: $color-white-700;
  $_color-close-icon-hover-bg: $color-white-900;
  $_color-focus-hover-font: $color-accent-blue-100;
  $_color-active-font: $color-accent-blue-100-70;
  $_color-focused-section-link-bg: $color-blue-800;

  $_this: &;

  background-color: $_color-bg;
  color: $_color-section-title;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transform: none;
  transition: transform $transition-slow;
  width: 31rem;

  &--hidden {
    transform: translate(calc(-100%));
    transition:
      transform $transition-slow,
      visibility $transition-slow;
    visibility: hidden;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    width: 100%;
  }

  &__close-icon-wrapper {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    padding: 2rem 2rem 1.8rem 0;
    width: 100%;

    #{$_this}__close-icon {
      border-radius: 50%;
      fill: $_color-section-title;

      &:hover {
        background-color: $_color-close-icon-hover-bg;
      }

      &:focus {
        background-color: $_color-close-icon-focus-bg;
        fill: $color-accent-blue-100;
      }

      &:active {
        fill: $_color-active-font;
      }
    }
  }

  &__main-navigation {
    flex: 1 1 0;
    overflow: auto;
    padding-left: $gutter-m;
  }

  &__link {
    border-radius: 0.4rem;
    color: $_color-link;
    font-size: $font-size-l;
    font-weight: $font-weight-regular;
    letter-spacing: 0.1rem;
    line-height: 1.5;
    text-decoration: none;

    &--bottom-navigation {
      padding: 0 2rem;
    }

    &--section {
      padding: 0 $gutter-base;

      &:not(:last-of-type) {
        margin-bottom: $gutter-base;
      }
    }

    span {
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;

      &::after {
        @include text-underline-basis;

        background-color: $_color-focus-hover-font;
        bottom: 0.2rem;
        height: $border-width-s;
        top: auto;
      }
    }

    &:hover {
      color: $_color-focus-hover-font;

      span {
        &::after {
          @include text-underline-focus;
        }
      }
    }

    &:active {
      color: $_color-active-font;
    }

    &:focus,
    &--selected {
      background-color: $_color-focused-section-link-bg;
      color: $_color-focus-hover-font;
    }
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: $gutter-xl;
    }
  }

  &__section-title {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    letter-spacing: 0.1rem;
    line-height: 1.2;
    margin-bottom: $gutter-base;
  }

  &__section-links {
    display: flex;
    flex-direction: column;
    padding: 0 $gutter-m;
  }

  &__bottom-navigation {
    border-top: 0.1rem solid $_color-bottom-navigation-border;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.6rem 0.4rem;
    width: 100%;
  }
}
