h1,
.heading1 {
  @include heading('h1');
}

h2,
.heading2 {
  @include heading('h2');
}

h3,
.heading3 {
  @include heading('h3');
}

h4,
.heading4 {
  @include heading('h4');
}

.paragraph {
  line-height: 1.4;
  margin: 0;
}
