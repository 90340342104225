@import 'src/scss/base/index';

.form-content {
  $_color-label-dark: $color-gray-300;

  box-sizing: border-box;
  overflow-y: auto;
  padding: $gutter-l;

  .input,
  .select-input {
    $_this: &;

    &--dark {
      #{$_this}__input {
        @include input-dark;
        border: 0;
      }

      &__label,
      &__placeholder {
        color: $_color-label-dark;
      }
    }
  }
}
