@import 'src/scss/base/index';

.notification-item {
  @include assessment-notification;

  &__details {
    @include assessment-notification-details;
  }

  &__details-button {
    @include assessment-notification-details-button;
  }

  &__time {
    @include assessment-notification-time;
  }
}
