@import 'src/scss/base/index';

.my-list-page {
  $_color-secondary-navigation-border: $color-white-900;

  margin-top: $height-header;

  &__btn-tab {
    margin-left: $gutter-base;
  }

  &__secondary-controls {
    align-items: center;
    display: flex;
  }

  &__secondary-navigation {
    @include controls-with-separator-right;
  }
}
