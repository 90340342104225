@import 'src/scss/base/index';

.ts-modal {
  $_color-footer-border: $color-blue-900-40;
  $_color-header-description-font: $color-blue-900-70;
  $_color-confirm-button-dark-bg: $color-blue-600;
  $_color-confirm-button-dark-font: $color-white;
  $_color-secondary-button-light-bg: $color-blue-900-15;
  $_color-secondary-button-hover-light-bg: $color-blue-900-20;
  $_color-secondary-button-light-font: $color-black;

  display: flex;
  flex-direction: column;
  left: calc(50% - 32.4rem);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 64.8rem;

  &:focus {
    outline: none;
  }

  &__header {
    padding: 2.4rem 2.4rem 1.6rem;
  }

  &__header-content {
    color: $_color-header-description-font;
    font-size: $font-size-base;
    letter-spacing: 0.01rem;
    line-height: 1.4;
    margin: $gutter-xl 0 0;
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: $font-size-xxl;
    line-height: 3.6rem;
  }

  &__close-container {
    align-self: center;
  }

  &__body {
    align-self: stretch;
    flex: 1;
    max-height: 40rem;
    padding: 0 2.4rem 6.6rem;

    /* stylelint-disable */
    @media screen and (forced-colors: active), (forced-colors: none) {
      flex: auto;
    }
    /* stylelint-enable */

    &--scrollY {
      overflow-y: auto;
    }
  }

  &__footer {
    border-top: 0.1rem solid $_color-footer-border;
    display: flex;
    flex-direction: column;
    padding: 1.6rem 2.4rem;
  }

  &__secondary-btn {
    background-color: $_color_secondary-button-light-bg;
    color: $_color-secondary-button-light-font;

    &:hover {
      background-color: $_color-secondary-button-hover-light-bg;
      color: $_color-secondary-button-light-font;
    }
  }

  &__footer-main-row {
    display: flex;
    justify-content: space-between;

    &--elements-inside-aligned-right {
      margin-left: auto;
    }
  }

  &__footer-upper-row {
    padding-bottom: $gutter-l;
  }

  &__btn--align-right {
    margin: 0 0 0 auto;

    &:not(:last-child) {
      margin-right: $gutter-base;
    }
  }
}
