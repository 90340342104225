@use 'sass:math';
@import 'src/scss/base/index';

.notifications {
  $_color-count-bg: $color-accent-red-200;
  $_color-count-indicator-bg: $color-white;

  $_font-size-count: 0.9rem;
  $_offset-count: 0.8rem;
  $_size-count: 1.2rem;
  $_size-count-m: 1.4rem;
  $_size-count-l: 1.8rem;
  $_size-count-indicator: math.div($_size-count, 3);

  @include z-index('drawer', 'container');

  $_this: &;

  &__count {
    align-items: center;
    background-color: $_color-count-bg;
    border: $border-width-s solid $color-header-bg;
    border-radius: 50%;
    display: flex;
    font-size: $_font-size-count;
    font-weight: $font-weight-bold;
    height: $_size-count;
    justify-content: center;
    position: absolute;
    right: -($_size-count - $_offset-count);
    top: 0;
    width: $_size-count;

    &--medium {
      height: $_size-count-m;
      right: -($_size-count-m - $_offset-count);
      top: -0.2rem;
      width: $_size-count-m;
    }

    &--large {
      height: $_size-count-l;
      right: -($_size-count-l - $_offset-count);
      top: -0.4rem;
      width: $_size-count-l;
    }

    &--indicator {
      &::before {
        background-color: $_color-count-indicator-bg;
        border-radius: 50%;
        content: '';
        height: $_size-count-indicator;
        width: $_size-count-indicator;
      }
    }
  }

  &__icon-wrapper {
    #{$_this}__icon {
      @include button-icon-header;

      &:focus,
      &:hover,
      &:active,
      &--drawer-open {
        @include button-icon-header-active;
      }

      &:focus,
      &:hover {
        @include button-icon-header-focus;
      }
    }
  }

  &__section {
    margin: $gutter-xl;

    &:first-of-type {
      margin-top: 0;
    }

    &:not(:first-of-type) {
      border-top: 0.1rem solid $color-drawer-border;
      padding-top: $gutter-l;
    }
  }
}

.assessment__notification {
  display: flex;
}
