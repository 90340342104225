$_color-separator-right-border: $color-white-900;

@mixin controls-with-separator-right() {
  align-items: center;
  display: flex;
  padding-right: $gutter-base;
  position: relative;

  &::after {
    background-color: $_color-separator-right-border;
    content: '';
    height: 2.4rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: $border-width-s;
  }
}
