$_color-btn-highlighted: $color-blue-900;
$_color-btn-highlighted-bg: $color-accent-green-100;
$_color-btn-highlighted-bg-focus: $color-accent-green-150;
$_color-btn-highlighted-box-shadow-focus: $color-accent-green-100-30;
$_color-btn-cautious: $color-blue-900;
$_color-btn-cautious-bg: $color-accent-yellow-100;
$_color-btn-cautious-bg-focus: $color-accent-yellow-200;
$_color-btn-cautious-box-shadow-focus: $color-accent-yellow-200-40;

@mixin btn-highlighted {
  background-color: $_color-btn-highlighted-bg;
  border-color: $_color-btn-highlighted-bg;
  color: $_color-btn-highlighted;
  fill: $_color-btn-highlighted;

  &:active {
    background-color: $_color-btn-highlighted-bg;
    box-shadow: none;
  }

  &:disabled {
    color: $_color-btn-highlighted;
    opacity: 0.6;
  }
}

@mixin btn-highlighted-focus {
  background-color: $_color-btn-highlighted-bg-focus;
  box-shadow: $box-shadow-size $_color-btn-highlighted-box-shadow-focus;
}

@mixin btn-cautious {
  background-color: $_color-btn-cautious-bg;
  border-color: $_color-btn-cautious-bg;
  color: $_color-btn-cautious;
  fill: $_color-btn-cautious;

  &:active {
    background-color: $_color-btn-cautious-bg;
    box-shadow: none;
  }

  &:disabled {
    color: $_color-btn-cautious;
    opacity: 0.6;
  }
}

@mixin btn-cautious-focus {
  background-color: $_color-btn-cautious-bg-focus;
  box-shadow: $box-shadow-size $_color-btn-cautious-box-shadow-focus;
}
