@import 'src/scss/base/index';

.overview-section {
  $_color-heading: $color-white-400;

  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-width: 71.7rem;
  padding-left: 1.6rem;

  & + & {
    margin-top: 2.6rem;
  }

  &__heading {
    color: $_color-heading;
    font-size: $font-size-s;
    font-weight: $font-weight-regular;
    width: 100%;
  }

  &__description {
    width: 100%;
    word-wrap: break-word;
  }

  &__button {
    margin-left: -#{$gutter-l};
    margin-top: $gutter-xl;
  }
}
