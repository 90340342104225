@import 'src/scss/base/index';

.checkbox {
  $_color-disabled: $color-gray-900;
  $_color-checkmark-light: $color-white;
  $_color-checkmark-dark: $color-black-700;

  $_this: &;

  align-items: center;
  align-self: flex-start;
  display: flex;
  margin-bottom: $gutter-l;

  &:hover {
    cursor: pointer;

    #{$_this}__icon-wrapper {
      &::before {
        opacity: 1;
      }
    }
  }

  &__icon-wrapper {
    @include z-index('checkbox', 'field');

    display: flex;
    position: relative;

    &::before {
      @include z-index('checkbox', 'focus-circle');
      @include checkbox-radio-focus-circle;

      left: -$size-check-radio-base * 0.5;
      top: -$size-check-radio-base * 0.5;
    }
  }

  &__input {
    &:focus {
      + #{$_this}__icon-wrapper {
        &::before {
          opacity: 1;
        }
      }
    }
  }

  &__label {
    margin-left: 1.4rem;

    &--disabled {
      color: $_color-disabled;
    }
  }

  &__icon {
    &--disabled {
      fill: $_color-disabled;

      #{$_this}-checked__bg {
        fill: $_color-disabled;
      }
    }
  }

  &--is-dark {
    #{$_this}__icon {
      fill: $_color-checkmark-dark;

      .checkbox-checked__checkmark {
        fill: $_color-checkmark-light;
      }
    }

    #{$_this}__icon-wrapper {
      &::before {
        @include checkbox-radio-focus-circle($is-dark: true);
      }
    }
  }
}
