@import 'src/scss/base/index';

.notifications-drawer {
  $_color-box-shadow: $color-black-600;
  $_color-icon: $color-blue-900;

  $_gutter-notifications: 2rem;

  box-shadow: 0 1rem 1.8rem -0.6rem $_color-box-shadow;

  &.drawer {
    top: $height-header - $gutter-base;
  }

  &__btn {
    margin: $gutter-base 0 $gutter-xl;
    width: 100%;
  }

  &__content {
    margin: 0 $_gutter-notifications 0 $gutter-xl;
  }

  &__icon {
    fill: $_color-icon;

    &--rotate {
      transform: rotate(90deg);
    }
  }

  &__header {
    padding: $_gutter-notifications $gutter-xl $gutter-base;
  }

  &__notifications {
    &--gutter-top {
      padding-top: $gutter-base;
    }

    &--new {
      border-bottom: $border-width-s solid $color-drawer-border;
      padding-bottom: $gutter-base;
    }
  }

  &__subheader {
    margin: $gutter-base 0;
  }

  &__text {
    padding: 0 0 $gutter-xl;

    &--gutter {
      padding: $height-drawer-upper-controls $gutter-xl $gutter-xl;
    }

    &--loading {
      align-items: center;
      display: flex;
      height: 4rem;
      justify-content: center;
      margin: $gutter-base 0 $gutter-xl;
      padding: 0;
    }
  }

  &--show-notifications {
    &.drawer {
      height: auto;
    }

    .drawer__wrapper {
      height: auto;
      max-height: calc(100vh - #{$height-header} - #{$gutter-base});
      overflow-y: auto;
    }
  }

  &__purchase-assessment-button {
    width: 100%;
  }
}
