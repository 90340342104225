@import 'src/scss/base/index';

.total-session-timer {
  $_color-bg-timer: $color-accent-red-50;

  background-color: $_color-bg-timer;
  font-size: $font-size-m;
  padding: $gutter-m $gutter-xl;
  text-align: center;

  &__content {
    margin: 0;
  }
}
