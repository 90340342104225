@import 'src/scss/base/index';

.purchase-assessment-content {
  $_color-divider-border: $color-black-200;
  $_color-select-all-assessments-type: $color-blue-500;
  $_color-dropdown-box-shadow: $color-black-250;
  $_color-dropdown-border: $color-blue-900-15;
  $_color-dropdown-secondary-text: $color-blue-900-70;
  $_color-bulk-select-input-bg-and-border: $color-white;

  &__assessments-list {
    list-style-type: none;
    margin-top: 0;
    padding: 0;
  }

  &__assessment {
    align-items: center;
    border-bottom: $border-width-s solid $_color-divider-border;
    display: flex;
    justify-content: space-between;
    padding: $gutter-s 0 $gutter-m;

    &:first-child {
      border-top: $border-width-s solid $_color-divider-border;
    }

    .select-input {
      margin-bottom: 0;
      width: 32rem;
    }
  }

  &__assessment-label {
    font-weight: $font-weight-bold;
    line-height: 1.7;
    margin-right: $gutter-base;
    width: 25.4rem;
  }
}
