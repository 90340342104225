@import 'src/scss/base/index';

.toggle {
  $_color-toggle-btn: $color-white-300;
  $_color-toggle-btn-checked: $color-white;
  $_color-toggle-btn-disabled: $color-blue-600;
  $_color-toggle-circle: $color-blue-500;
  $_color-toggle-circle-checked: $color-accent-blue-100;
  $_color-toggle-circle-disabled: $color-white-600;

  $_color-dark-bg: $color-blue-900-30;
  $_color-dark-bg-selected: $color-blue-900;
  $_color-dark-bg-label: $color-black;

  $_box-shadow-btn-active: 0 0 0 0.4rem $color-blue-300-40;
  $_box-shadow-circle-focus: 0 0 0 0.9rem $color-blue-300-40;
  $_size-toggle-circle: 1rem;
  $_width-toggle: 2.8rem;

  $_this: &;
  align-items: center;
  display: flex;
  position: relative;

  &__btn {
    cursor: pointer;
    position: relative;

    &::before {
      background-color: $_color-toggle-btn;
      border-radius: 10rem;
      box-sizing: border-box;
      content: '';
      height: 1.4rem;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      transition:
        background-color $transition-base,
        box-shadow $transition-base;
      width: $_width-toggle;
    }

    &::after {
      background-color: $_color-toggle-circle;
      border-radius: 50%;
      content: '';
      height: $_size-toggle-circle;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateX($gutter-xs) translateY(-50%);
      transition:
        background-color $transition-base,
        box-shadow $transition-base,
        transform $transition-base;
      width: $_size-toggle-circle;
    }

    &:hover {
      &::after {
        box-shadow: $_box-shadow-circle-focus;
      }

      + #{$_this}__tooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    &--checkbox-checked {
      &::before {
        background-color: $_color-toggle-btn-checked;
      }

      &::after {
        background-color: $_color-toggle-circle-checked;
        transform: translateX(-$gutter-xs) translateX($_width-toggle)
          translateX(-100%) translateY(-50%);
      }
    }
  }

  &__hidden-input {
    &:focus {
      + #{$_this}__btn {
        &::after {
          box-shadow: $_box-shadow-circle-focus;
        }
      }
    }

    &:active {
      + #{$_this}__btn {
        &::before {
          box-shadow: $_box-shadow-btn-active;
        }

        &::after {
          box-shadow: none;
        }
      }
    }

    &:disabled {
      + #{$_this}__btn {
        opacity: 0.6;
      }
    }
  }

  &__label {
    color: $color-text-dark-bg-200;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    margin-left: $_width-toggle + $gutter-xxl;
    transition: color $transition-base;

    &--checkbox-checked {
      color: $color-text-dark-bg-100;
    }
  }

  &--disabled {
    pointer-events: none;
  }

  &--dark-bg {
    #{$_this}__btn {
      &::before {
        background-color: $_color-dark-bg;
      }

      &--checkbox-checked {
        &::before {
          background-color: $_color-dark-bg-selected;
        }
      }
    }

    #{$_this}__label {
      color: $_color-dark-bg-label;
      font-weight: $font-weight-regular;
      margin-left: $_width-toggle + $gutter-l;
    }
  }
}
