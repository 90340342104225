@import 'src/scss/base/index';

.breadcrumbs {
  $_color-breadcrumb: $color-text-dark-bg-300;
  $_color-breadcrumb-focus: $color-white;

  margin-bottom: $gutter-base;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    list-style: none;
  }

  &__link {
    color: $_color-breadcrumb;
    line-height: 1.5;
    outline: none;
    position: relative;
    text-decoration: none;
    transition: color $transition-slow ease-out;

    &::after {
      @include text-underline-basis;

      background-color: $_color-breadcrumb-focus;
      height: $border-width-s;
    }

    &:focus,
    &:hover {
      color: $_color-breadcrumb-focus;

      &::after {
        @include text-underline-focus;
      }
    }
  }

  &__separator {
    color: $_color-breadcrumb;
    margin: 0 $gutter-s;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }

  &__right {
    color: $_color-breadcrumb;
    line-height: 1.5;
    margin-bottom: $gutter-base;
  }
}
