@import 'src/scss/base/index';

.btn-dark {
  $_this: &;

  background-color: $color-btn-bg;
  color: $color-btn;

  &:focus,
  &:hover,
  &--focused,
  &--dropdown-open {
    box-shadow: $box-shadow-size $color-btn-box-shadow-focus;
    cursor: pointer;
  }

  &--disabled,
  &:disabled {
    border-color: $color-btn-disabled;
    color: $color-btn-disabled;
    pointer-events: none;

    #{$_this}__icon {
      fill: $color-btn-disabled;
    }
  }

  &--primary {
    border-color: $color-btn-primary-border;

    &:focus,
    &:hover,
    &#{$_this}--focused,
    &#{$_this}--dropdown-open {
      background-color: $color-btn-primary-bg-focus;
      border-color: transparent;
      color: $color-btn-primary-focus;

      #{$_this}__icon {
        fill: $color-btn-primary-focus;
      }
    }

    &:active {
      background-color: $color-btn-bg;
      box-shadow: none;
      color: $color-btn;

      #{$_this}__icon {
        fill: $color-btn;
      }
    }
  }

  &--secondary {
    &:focus,
    &:hover,
    &#{$_this}--focused,
    &#{$_this}--dropdown-open {
      background-color: $color-btn-secondary-bg-focus;
      color: $color-btn-secondary-focus;

      #{$_this}__icon {
        fill: $color-btn-secondary-focus;
      }
    }

    &:active {
      background-color: $color-btn-bg;
      box-shadow: none;
      color: $color-btn;

      #{$_this}__icon {
        fill: $color-btn;
      }
    }

    &:disabled {
      border: 0;
    }
  }
}

.btn-light {
  $_color-btn-light: $color-black;

  $_this: &;
  color: $_color-btn-light;

  &:focus,
  &:hover,
  &--focused,
  &--dropdown-open {
    box-shadow: $box-shadow-size $color-btn-light-box-shadow-focus;
    cursor: pointer;
  }

  &:active {
    box-shadow: none;
  }

  &--disabled,
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  #{$_this}__icon {
    fill: $_color-btn-light;
  }

  &--primary {
    background-color: $color-btn-light-primary-bg;
    border-color: transparent;

    &:focus,
    &:hover,
    &#{$_this}--focused,
    &#{$_this}--dropdown-open {
      background-color: $color-btn-light-primary-bg-focus;
    }

    &:active {
      background-color: $color-btn-light-primary-bg;
      color: $_color-btn-light;
    }
  }

  &--secondary {
    background-color: $color-btn-light-secondary-bg;
    color: $_color-btn-light;
    font-weight: $font-weight-regular;

    &:focus,
    &:hover,
    &#{$_this}--focused,
    &#{$_this}--dropdown-open {
      background-color: $color-btn-light-secondary-bg;
    }
  }
}

.btn {
  $_this: &;
  align-items: center;
  border: 0.1rem transparent solid;
  border-radius: $border-radius-base;
  box-sizing: border-box;
  display: flex;
  font-weight: $font-weight-medium;
  height: 3.2rem;
  justify-content: center;
  min-width: 7.2rem;
  outline: 0;
  padding: 0 $gutter-m;
  text-decoration: none;
  transition:
    box-shadow $transition-base,
    background-color $transition-base,
    color $transition-base,
    opacity $transition-base;

  &__icon {
    align-items: center;
    display: flex;
    fill: $color-btn;
    transition: fill $transition-base;

    &--following {
      margin-right: -$gutter-s;
      padding-left: $gutter-s;
    }

    &--leading {
      margin-left: -$gutter-s;
      padding-right: $gutter-s;
    }
  }

  &__text {
    align-items: center;
    display: flex;
    line-height: 1;
  }

  &__spinner {
    margin-right: $gutter-base;
  }

  &--highlighted {
    @include btn-highlighted;

    &:focus,
    &:hover {
      @include btn-highlighted-focus;
    }
  }

  &--small {
    border-radius: $border-radius-l;
    font-size: $font-size-s;
    height: 2.4rem;
    min-width: 3.2rem;
    padding: 0 1rem;
  }

  &--large {
    height: 4rem;
  }
}

.btn-tab {
  @include button-tab;
  $_color-inline-tab: $color-blue-900-50;
  $_this: &;

  &:focus,
  &:hover {
    &:not(#{$_this}--inline) {
      @include button-tab-focus;
    }

    &#{$_this}--inline {
      @include button-tab-inline-focus;
    }
  }

  &:active,
  &--active {
    &:not(#{$_this}--inline) {
      @include button-tab-active;
    }

    &#{$_this}--inline {
      @include button-tab-inline-active;
    }
  }

  &:disabled,
  &--disabled {
    @include button-tab-disabled;
  }

  &--inline {
    color: $_color-inline-tab;
    display: inline-flex;
  }

  &--inline + &--inline {
    margin-left: $gutter-m;
  }

  &__icon {
    display: flex;
  }
}

.btn-link {
  align-items: center;
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  display: flex;
  font-weight: $font-weight-medium;
  outline: 0;
  padding: 0 0 $gutter-xs;
  position: relative;
  transition: color $transition-slow ease-out;

  &::after {
    @include text-underline-basis;

    height: 0.2rem;
  }

  &:focus,
  &:hover {
    cursor: pointer;

    &::after {
      @include text-underline-focus;
    }
  }

  &--dropdown-open {
    &:focus,
    &:hover {
      &::after {
        transform: scaleX(0);
      }
    }
  }

  &__icon {
    align-items: center;
    display: flex;

    &--following {
      padding-left: $gutter-xs;
    }
  }

  &--primary {
    color: $color-misc-primary;
    fill: $color-misc-primary;

    &::after {
      background-color: $color-misc-primary;
    }
  }

  &--secondary {
    color: $color-misc-secondary;
    fill: $color-misc-secondary;

    &::after {
      background-color: $color-misc-secondary-focus;
    }

    &:focus,
    &:hover,
    &.has-dropdown-open {
      color: $color-misc-secondary-focus;
      fill: $color-misc-secondary-focus;
    }
  }
}
