@use 'src/scss/base/color-palette' as colors;

@use '~ag-grid-community/styles' as ag;
// @import '~@ag-grid-community/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin';

$_checkbox-icons-path: '../../assets/';
$_height-table-header-separator: 1.6rem;
$_height-table-row: 4rem;
$_height-filter-row: 5rem;
$_table-icons: (tree-closed, tree-open);
$_table-icons-path: '../../../assets/table/';

@include ag.grid-styles(
  (
    theme: alpine-dark,
    background-color: $color-table-bg,
    border-color: $color-table-border-primary,
    borders: false,
    borders-critical: true,
    font-family: $font-primary,
    header-background-color: $color-table-bg,
    header-column-separator-display: true,
    header-column-separator-color: $color-table-border-primary,
    header-column-separator-height: $_height-table-header-separator,
    odd-row-background-color: null,
    row-hover-color: null,
    secondary-border-color: $color-table-border-secondary,
  )
);

.ag-theme-alpine-dark {
  $_color-unsorted-sort-indicator: colors.$color-white-800;

  margin: {
    left: -$gutter-page;
    right: -$gutter-page;
  }

  @each $icon in $_table-icons {
    .ag-icon.ag-icon-#{$icon} {
      @include table-icon($_table-icons-path, $icon);

      &::before {
        content: '' !important;
      }
    }
  }

  .ag-body-viewport {
    flex-grow: 0;
    height: auto;
  }

  .ag-cell,
  .ag-header-cell {
    padding: 0 $gutter-l;

    &.first-cell {
      padding-left: $gutter-xl;
    }

    &.last-cell {
      padding-right: $gutter-xl;
    }

    &.right-aligned {
      text-align: right;
    }
  }

  .ag-center-cols-clipper {
    min-height: $_height-table-row !important;
  }

  .ag-checkbox-input {
    &:focus,
    &:hover {
      cursor: pointer;
    }
  }

  .ag-checkbox-input-wrapper {
    background: none;
    box-shadow: none !important;

    &:focus,
    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &::before {
      @include checkbox-radio-focus-circle;

      left: -$size-check-radio-base * 0.5;
      top: -$size-check-radio-base * 0.5;
    }

    &::after {
      @include z-index('checkbox', 'field');

      content: url('#{$_checkbox-icons-path}checkbox-unchecked.svg');
      height: $size-check-radio-base;
      width: $size-check-radio-base;
    }

    &.ag-checked {
      &::after {
        content: url('#{$_checkbox-icons-path}checkbox-checked.svg');
      }
    }

    &.ag-indeterminate {
      &::after {
        content: url('#{$_checkbox-icons-path}checkbox-indeterminate.svg');
      }
    }
  }

  .ag-filter-icon {
    display: none;
  }

  .ag-header-cell {
    border-top-color: transparent;
    padding: 0 $gutter-l;

    &:last-of-type {
      padding-right: $gutter-xl;

      &::after {
        content: none;
      }
    }
  }

  .ag-header-row-floating-filter {
    height: $_height-filter-row !important;

    .ag-header-cell {
      &::after {
        content: none;
      }
    }
  }

  .ag-floating-filter-full-body {
    overflow: visible;
  }

  .ag-header-cell-sortable {
    transition: background-color $transition-base;

    &:focus,
    &:hover {
      background-color: $color-table-row-hover;

      &::after {
        background-color: $color-table-border-primary !important;
        border: 0 !important;
        height: $_height-table-header-separator !important;
        left: auto !important;
        top: calc(50% - #{$_height-table-header-separator} / 2) !important;
        width: $border-width-s !important;
      }

      &:last-of-type {
        &::after {
          content: none !important;
        }
      }

      .ag-header-cell-sorted-none {
        .ag-sort-descending-icon {
          color: $_color-unsorted-sort-indicator;
          display: block !important;
        }
      }
    }
  }

  .ag-header-container {
    width: 100%;
  }

  .ag-header-row {
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    height: $_height-table-row;

    &:not(:first-child) {
      .ag-header-cell {
        border-top: 0;
      }
    }
  }

  .ag-horizontal-left-spacer {
    border: 0 !important;
  }

  .ag-pinned-left-cols-container {
    @include z-index('ag-grid', 'pinned-left-column');
  }

  .ag-pinned-right-cols-container {
    @include z-index('ag-grid', 'pinned-right-column');

    position: absolute;
    right: 0;

    .ag-cell-value {
      width: 100%;
    }

    .ag-react-container {
      width: 100%;
    }

    .ag-row {
      background-color: transparent !important;
      border: 0 !important;

      &:not(.ag-row-level-0) {
        .ag-cell {
          &:not(.ag-cell-focus) {
            border: 0 !important;
          }
        }
      }
    }

    .ag-row-level-0 {
      border: 0 !important;
      transition: opacity $transition-base;
      opacity: 0;

      &.ag-row-focus,
      &.ag-row-hover {
        opacity: 1;
      }

      .ag-cell {
        $_color-bg: $color-blue-400;
        $_color-bg-focus: $color-blue-350;

        background-color: $_color-bg;
        border: 0 !important;
        border-radius: 0;
        fill: $color-text-dark-bg-100;
        font-family: $font-primary;
        font-size: $font-size-s;
        font-weight: $font-weight-medium;
        height: 4rem;
        transition: background-color $transition-base;
        width: 100%;

        &:focus,
        &:hover {
          background-color: $_color-bg-focus;
          box-shadow: none;
          cursor: pointer;
        }
      }
    }
  }

  .ag-pinned-right-header {
    border-left: 0;

    .ag-header-row {
      border: 0;

      &::after {
        content: none !important;
      }
    }
  }

  .ag-row-group {
    &.ag-row-level-0 {
      border: 0;
      font-weight: $font-weight-bold;
    }
  }

  div {
    &[role='gridcell'][aria-colindex='1'] {
      padding-left: $gutter-xl;
    }
  }

  .ag-row-group-expanded,
  .ag-row-group-contracted {
    .ag-group-contracted {
      display: flex !important;
    }

    .ag-group-expanded {
      display: none !important;
    }
  }

  .ag-row-group-expanded {
    .ag-group-contracted {
      transform: rotate(90deg);
    }
  }

  .select-cell {
    &.ag-cell {
      padding: 0;
    }

    &.ag-cell-inline-editing {
      border: $border-width-s solid transparent;
      border-radius: 0;
      padding: 0;
    }
  }

  &.use-remaining-height {
    height: 100% !important;

    .ag-body-viewport {
      height: 100%;
    }
  }
}
