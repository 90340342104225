@import 'src/scss/base/index';

.purchase-modal-content {
  $_color-divider-border: $color-black-200;
  $_color-assessment-label-border: $color-blue-900-15;
  $_color-assessment-label-main-text: $color-blue-700;
  $_color-assessment-label-secondary-text: $color-blue-900-70;
  $_color-cost-number: $color-black;
  $_color-spinner-stroke: $color-gray-300-30;
  $_input-width: 32rem;

  &__loading-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 34rem;
    justify-content: center;
  }

  &__spinner {
    .spinner {
      &__circle {
        stroke: $_color-spinner-stroke;
      }
    }
  }

  &__loading-text {
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
  }

  &__assessments-list {
    list-style-type: none;
    margin-top: 0;
    padding: 0;
  }

  &__assessment {
    border-top: $border-width-s solid $_color-divider-border;
    padding: $gutter-l 0;
  }

  &__label-with-input {
    display: flex;

    .select-input {
      margin-bottom: 0;
      min-width: $_input-width;
      width: $_input-width;
    }
  }

  &__assessment-label {
    color: $_color-assessment-label-main-text;
    flex-grow: 1;
    font-weight: $font-weight-bold;
    line-height: 1.7;
    margin: auto $gutter-base auto 0;
    position: relative;

    &::after {
      border-right: $border-width-s solid $_color-assessment-label-border;
      bottom: 18%;
      content: '';
      max-height: 2.4rem;
      position: absolute;
      right: 0;
      top: 18%;
    }
  }

  &__assessment-label-subtitle {
    color: $_color-assessment-label-secondary-text;
    display: block;
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    line-height: 1.2;
  }

  &__cost {
    align-items: baseline;
    color: $_color-assessment-label-secondary-text;
    display: flex;
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    justify-content: space-between;
    line-height: 1.2;
    margin: $gutter-xs 0 0 auto;
    width: $_input-width;
  }

  &__cost-number {
    color: $_color-cost-number;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    margin-top: $gutter-s;

    &--zero {
      color: $_color-assessment-label-secondary-text;
    }
  }
}
