@import 'src/scss/base/index';

.upload-third-parties {
  $_color-btn-dark: $color-white;
  $_color-btn-light: $color-black-600;
  $_color-btn-light-disabled: $color-black-200;

  $_size-modal: 47.2rem;
  $_min-height-modal: 32.2rem;
  $_max-height-modal: 60rem;

  $_this: &;
  display: flex;
  flex-direction: column;
  left: 50%;
  outline: none;
  position: relative;
  top: 13.5rem;
  transform: translateX(-50%);
  width: $_size-modal;

  &ts-modal__header-content &__btn-icon {
    position: absolute;
    right: 0;
    transform: translate(-50%, -50%);
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: $gutter-s $gutter-xxl;
  }

  // Wrapper needed for IE support
  &__content-wrapper {
    display: flex;
    max-height: $_max-height-modal;
    min-height: $_min-height-modal;
  }

  &__controls {
    align-items: center;
    display: flex;
    margin-top: auto;

    &--flex-end {
      justify-content: flex-end;
    }

    &--space-between {
      justify-content: space-between;
    }

    #{$_this}__btn {
      border-color: transparent;

      &:first-of-type {
        &:not(:last-of-type) {
          margin-right: 1.2rem;
        }
      }

      &--dark {
        color: $_color-btn-dark;

        &:focus,
        &:hover {
          color: inherit;
        }
      }

      &--light {
        color: $_color-btn-light;
        font-weight: $font-weight-medium;
      }

      &.is-disabled {
        color: $_color-btn-light-disabled;
        pointer-events: none;
      }
    }

    #{$_this}__description {
      margin-right: $gutter-l;
    }
  }

  .ts-modal__header {
    padding: $gutter-xl $gutter-xxl 0;
  }

  .ts-modal__header-content {
    color: $color-black;
    margin: $gutter-base 0 $gutter-l;
  }

  .ts-modal__close-container {
    align-self: normal;
  }

  .ts-modal__body {
    padding: 0 0 $gutter-l;
  }

  .find-assessments,
  .upload-file {
    width: $width-drawer;
  }
}
