@import 'src/scss/base/index';

.tooltip {
  $_color-tooltip: $color-blue-800;
  $_color-tooltip-bg: $color-blue-300;
  @include z-index('tooltip', 'text');

  background-color: $_color-tooltip-bg;
  border-radius: $border-radius-s;
  color: $_color-tooltip;
  font-family: $font-primary;
  font-size: $font-size-s;
  font-weight: $font-weight-regular;
  left: -4rem;
  line-height: 1;
  opacity: 0;
  padding: $gutter-s;
  pointer-events: none;
  position: absolute;
  top: 3.5rem;
  transition:
    opacity $transition-base ease-in-out $transition-delay,
    visibility $transition-base ease-in-out $transition-delay;
  visibility: hidden;
  white-space: nowrap;

  &--hidden {
    display: none;
  }

  &--bottom-center {
    left: 0;
  }

  &--bottom-right {
    left: auto;
    right: 0;
  }

  &--right {
    left: calc(100% + 0.5rem);
    top: calc(50% - 1rem);
  }

  &--top-center {
    bottom: calc(100% - 0.9rem);
    left: 50%;
    top: auto;
    transform: translateX(-50%);
  }

  &--top-left {
    bottom: calc(100% + 0.5rem);
    left: 0;
    top: auto;
  }

  &--top-right {
    bottom: calc(100% + 0.5rem);
    left: auto;
    right: 0;
    top: auto;
  }
}
