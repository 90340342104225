@import 'src/scss/base/index';

.download-template {
  $_this: &;
  display: flex;
  flex-direction: column;
  height: 25.2rem;

  &__btn {
    @include file-info;

    border: 0.4rem solid transparent;
    box-shadow: $box-shadow-file-transfer;
    outline: none;
    transition: border-color $transition-base;

    &:focus,
    &:hover {
      border-color: $color-file-transfer-border-focus;
      cursor: pointer;

      #{$_this}__download-icon {
        background-color: $color-black-100;
        opacity: 1;
      }
    }
  }

  &__btn-content {
    align-items: center;
    display: flex;
  }

  &__btn-text {
    @include file-transfer-text;

    &--secondary {
      @include file-transfer-text-secondary;
    }
  }

  &__btn-text-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }

  &__download-icon {
    border-radius: 50%;
    fill: $color-blue-900;
    margin-left: auto;
    opacity: 0.4;
    padding: 0.4rem;
    position: relative;
    transition:
      background-color $transition-base,
      opacity $transition-base;
  }
}
