@import 'src/scss/base/index';

.purchase-modal {
  $_color-cost-text: $color-black;
  $_font-size-cost-text: 1.8rem;

  &__total-cost {
    color: $_color-cost-text;
    display: flex;
    font-size: $_font-size-cost-text;
    font-weight: bold;
    justify-content: space-between;
    margin-left: auto;
    width: 50%;
  }

  .ts-modal {
    &__header {
      padding-bottom: $gutter-base;
    }

    &__body {
      max-height: 34rem;
      min-height: 34rem;
      overflow-y: auto;
    }

    &__footer {
      padding: $gutter-base $gutter-xl $gutter-l;

      &-upper-row {
        padding-bottom: 3rem;
      }
    }
  }
}
