@import 'src/scss/base/index';

.option {
  $_color-text: $color-black;
  $_color-admin-icon: $color-accent-green-100;
  $_color-user-icon: $color-blue-300;
  $_color-user-shield-icon: $color-accent-orange;

  align-items: center;
  color: $_color-text;
  display: flex;
  margin-top: $gutter-base;

  &__icon {
    display: flex;
    padding: $gutter-xs 0;
  }

  &__body {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  &__icon + &__body {
    padding-left: $gutter-l;
  }

  &__title {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    line-height: 1.1;
  }

  &__description {
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    line-height: 1.2;
  }

  &__title + &__description {
    margin-top: $gutter-base;
  }

  .checkbox {
    align-self: center;
    margin: 0;
  }
}
