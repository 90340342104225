@import 'src/scss/base/index';

.form-controls {
  $_color-border: $color-blue-400;

  bottom: 0;
  box-sizing: border-box;
  display: flex;
  left: 0;
  padding: $gutter-l;
  position: absolute;
  width: 100%;

  &--buttons-aligned-right {
    justify-content: flex-end;
  }

  &::before {
    background-color: $_color-border;
    content: '';
    height: $border-width-s;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__button {
    &:not(:last-of-type) {
      margin-right: $gutter-base;
    }
  }

  &__spinner {
    margin-right: $gutter-base;
  }
}
