@import 'src/scss/base/index';

.catalog-section {
  $_color-download-disabled: $color-black-400;

  &__download-wrapper {
    position: relative;

    .dropdown-menu {
      right: 0;
      width: auto;
      min-width: 150px;

      &__item-contents {
        > svg {
          fill: $color-text-light-bg;
          margin-left: 0.2rem;
          margin-right: 1rem;
          vertical-align: middle;
        }

        > span {
          vertical-align: middle;
        }
      }

      &__item:disabled {
        color: $_color-download-disabled;
        cursor: auto;

        svg {
          fill: $_color-download-disabled;
        }
      }
    }
  }
}
