@import 'src/scss/base/index';

.select-wrapper {
  color: #06080c;

  .select-wrapper__control {
    background-color: #374262;
    color: rgb(204, 204, 204);
    cursor: pointer;
    outline: 0.2rem solid #0000;
    outline-offset: -0.2rem;
    display: flex;
    height: 3.2rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.8rem;

    .select-wrapper__single-value {
      color: rgb(204, 204, 204);
    }
  }

  &:hover {
    background-color: #232a3f;
    box-sizing: border-box;
    outline: none;
    position: relative;

    .select-wrapper__control {
      background-color: #232a3f;
      transition: background-color 0.2s;
    }

    .select-wrapper__placeholder {
      color: #fff;
      font-size: 1.4rem;
      font-weight: 700;
    }

    .select-wrapper__single-value {
      color: #fff;
      font-size: 1.4rem;
      font-weight: 700;
    }
  }
}
