@import 'src/scss/base/index';

.input {
  $_color-accent-active: $color-accent-blue-100;
  $_color-label-dark: $color-gray-300;
  $_color-explanation-dark: $color-text-dark-bg-200;

  $_this: &;
  @include input-base;

  &__wrapper {
    position: relative;
    width: 100%;
  }

  &__label {
    @include label-position;
    @include label-font-size($add-color: true);
    @include input-required;

    pointer-events: none;
    position: absolute;
    transition:
      font-size $transition-base,
      top $transition-base,
      transform $transition-base;

    &.is-active {
      @include label-active;
    }
  }

  &__explanation {
    color: $_color-explanation-dark;
    font-size: $font-size-s;
    margin-top: $gutter-s;
  }

  &__input {
    @include input-size;
    @include input-light;
    appearance: none;
    caret-color: $_color-accent-active;
    text-overflow: ellipsis;
    width: 100%;
  }

  &--dark {
    #{$_this}__input {
      @include input-dark;
    }

    #{$_this}__label {
      color: $_color-label-dark;
      @include input-required($is-light: false);
    }
  }
}
