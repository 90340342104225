$_okta-icons-path: '../../assets/';

#sign-in-widget {
  $_color-login-btn-bg: $color-accent-blue-100;
  $_color-login-btn-bg-active: $color-accent-blue-150;
  $_color-login-btn-box-shadow: $color-accent-blue-100-30;
  $_color-login-field-bg: $color-blue-600;
  $_color-login-field-bg-disabled: $color-blue-600-50;
  $_color-login-field-bg-focus: $color-blue-800;
  $_color-login-field-border-focus: $color-accent-blue-100;
  $_color-login-field-border-error: $color-accent-red-100;
  $_color-login-field-box-shadow: $color-accent-blue-100-20;
  $_color-login-field-box-shadow-error: $color-accent-red-100-20;
  $_color-login-password-btn-bg: $color-blue-500;

  $_box-shadow-size-webkit: 0 0 0 10rem;
  $_height-login-input: 4.8rem;
  $_text-password-show: 'Show';
  $_text-password-hide: 'Hide';

  #okta-sign-in {
    &.auth-container {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      font-family: $font-primary;
      margin: 4rem 0 0;
      max-width: 32rem;
      overflow: visible;

      .animation-container-overflow {
        display: flex;
      }

      .auth-content {
        padding: 0;
      }

      .auth-header {
        display: none;
      }

      .button,
      .button-primary {
        background: $_color-login-btn-bg;
        border: 0;
        border-radius: $border-radius-base;
        color: $color-text-light-bg;
        font-family: $font-primary;
        font-weight: $font-weight-medium;
        height: $_height-login-input;
        margin-bottom: $gutter-xxl;
        transition:
          background-color $transition-base,
          box-shadow $transition-base;

        &:focus,
        &:hover {
          box-shadow: $box-shadow-size $_color-login-btn-box-shadow;
          cursor: pointer;
        }

        &:active {
          background: $_color-login-btn-bg-active;
          box-shadow: none;
        }
      }

      .custom-checkbox {
        margin: $gutter-l 0 $gutter-xl;

        input {
          &:focus,
          &:hover {
            & + label {
              &::after {
                opacity: 1;
              }
            }
          }
        }

        label {
          background-image: none;
          color: $color-text-dark-bg-200;
          font-size: $font-size-base;
          padding: 0.8rem 2.8rem 0;

          &::before {
            @include z-index('checkbox', 'field');

            content: url('#{$_okta-icons-path}checkbox-unchecked.svg');
            height: $size-check-radio-base;
            left: 0;
            position: absolute;
            width: $size-check-radio-base;
          }

          &::after {
            @include checkbox-radio-focus-circle;

            background-color: $_color-login-field-bg;
            left: -$size-check-radio-base * 0.5;
            top: 0;
          }

          &.checked {
            &::before {
              content: url('#{$_okta-icons-path}checkbox-checked.svg');
            }
          }
        }
      }

      .focused-input {
        box-shadow: none;
      }

      .eyeicon {
        @include z-index('login-form', 'password-toggle');

        height: 0;
        opacity: 1;
        padding: 0 $gutter-l 0 0;
        top: 1.2rem;
        transform: none;
        width: 0;

        &::before {
          background-color: $_color-login-password-btn-bg;
          border: 0;
          border-radius: $border-radius-l;
          color: $color-text-dark-bg;
          content: $_text-password-show;
          font-family: $font-primary;
          font-size: $font-size-s;
          right: 1rem;
          padding: 0.4rem 0;
          text-align: center;
          width: 4.5rem;
        }

        &.button-hide {
          &::before {
            content: $_text-password-hide;
          }
        }

        &:focus,
        &:hover {
          &::before {
            background-color: $color-btn-secondary-bg-focus;
            box-shadow: $box-shadow-size $color-btn-box-shadow-focus;
          }
        }

        &:active {
          &::before {
            background-color: $color-btn-bg;
            box-shadow: none;
          }
        }
      }

      .error-16,
      .error-16-small {
        &::before {
          content: url('#{$_okta-icons-path}error-input.svg');
        }
      }

      .help-links {
        li {
          margin-top: $gutter-base;
        }
      }

      .icon-16 {
        left: 0;
      }

      .link {
        color: $color-text-dark-bg-200;
        display: block;
        font-weight: $font-weight-medium;
        text-align: center;

        &.help {
          &:focus {
            box-shadow: none;
          }
        }
      }

      .margin-btm-5,
      .margin-btm-30 {
        margin-bottom: 0;
      }

      .o-form-button-bar {
        padding: 0;
      }

      .o-form-fieldset {
        position: relative;
      }

      .o-form-input-error {
        color: $color-text-error;
        font-size: $font-size-s;
        padding: 0.8rem 0 0 2rem;
      }

      .o-form-label {
        @include z-index('login-form', 'label');

        color: $color-text-dark-bg-200;
        cursor: text;
        font-weight: $font-weight-regular;
        left: $gutter-l;
        padding: $gutter-base 0;
        pointer-events: none;
        position: absolute;
        top: $_height-login-input * 0.5;
        transition: transform $transition-base;
        width: unset;

        &.active-input {
          transform: translateY(-1rem);

          label {
            font-size: $font-size-s;
          }
        }

        label {
          cursor: text;
          transition: font-size $transition-base;
        }
      }

      .o-form-theme {
        padding: 0;
      }

      .okta-form-infobox-error {
        background-color: transparent;
        border: 0;
        color: $color-text-error;
        font-size: $font-size-s;
        margin: $gutter-base 0 0 0;
        padding: 0 !important;

        &::before {
          content: none;
        }

        .error-16 {
          left: 0;
          top: 0;
        }

        p {
          padding-left: 2rem;
        }
      }

      .okta-form-input-field {
        background-color: transparent;
        border: 0;
        border-radius: $border-radius-base;
        height: $_height-login-input;
        margin-top: $gutter-l;

        &.o-form-disabled {
          opacity: 1;

          input {
            background-color: $_color-login-field-bg-disabled;
            border-color: transparent;
            color: $color-text-dark-bg-200;
            -webkit-text-fill-color: $color-text-dark-bg-200;
          }
        }

        &.o-form-has-errors {
          input {
            border-color: $_color-login-field-border-error;

            &:focus,
            &:hover {
              border-color: $_color-login-field-border-error;
              box-shadow: $box-shadow-size $_color-login-field-box-shadow-error;
            }
          }
        }

        &:not(.o-form-disabled) {
          input {
            &:focus,
            &:hover {
              background-color: $_color-login-field-bg-focus;
              border-color: $_color-login-field-border-focus;
              box-shadow: $box-shadow-size $_color-login-field-box-shadow;
            }
          }
        }

        input {
          background-color: $_color-login-field-bg;
          border: 0.1rem solid $_color-login-field-bg;
          border-radius: $border-radius-base;
          caret-color: $_color-login-field-border-focus;
          color: $color-text-dark-bg;
          filter: none;
          font-family: $font-primary;
          padding: $gutter-l $gutter-l 0 $gutter-l;
          transition:
            background-color $transition-base,
            border-color $transition-base,
            box-shadow $transition-base;

          &:-webkit-autofill {
            background-clip: text;
            box-shadow: $_box-shadow-size-webkit $_color-login-field-bg inset;
            -webkit-text-fill-color: $color-text-dark-bg;

            &:focus,
            &:hover {
              box-shadow:
                $_box-shadow-size-webkit $_color-login-field-bg-focus inset,
                $box-shadow-size $_color-login-field-box-shadow;
            }
          }
        }
      }

      .okta-form-title {
        @include heading('h1');

        color: $color-white;
        text-align: left;
      }

      #okta-signin-password {
        // Hide default Safari icon
        &::-webkit-credentials-auto-fill-button {
          visibility: hidden;
        }

        // Hide default IE and Edge icon
        &::-ms-reveal,
        &::-ms-clear {
          display: none;
        }
      }

      @include respond-to-height($breakpoint-height-768, max) {
        margin-top: 2rem;
      }
    }
  }
}
