@import 'src/scss/base/index';

.drawer {
  $_color-bg: $color-white;
  $_color-star-is-watched: $color-black;
  $_color-star-not-watched: $color-black-250;

  $_gradient-upper-controls:
    rgba($_color-bg, 1) 20%,
    rgba($_color-bg, 0);

  @include z-index('drawer', 'container');

  $_this: &;
  background-color: $_color-bg;
  border-radius: $border-radius-l;
  box-sizing: border-box;
  color: $color-text-light-bg;
  height: calc(100vh - #{$height-header} - #{$gutter-l});
  overflow: hidden;
  position: fixed;
  right: $gutter-xl;
  top: $height-header;
  transform: none;
  transition: transform $transition-slow;
  visibility: visible;
  width: 40rem;

  &::after {
    content: '';
    height: calc(100vh - #{$height-header} - #{$gutter-l});
    position: fixed;
    right: 0;
    top: $height-header;
    width: 2.4rem;
  }

  &__content {
    height: 100%;
    max-width: 100%;
    overflow-y: auto;
    position: relative;
    width: 100%;

    &--no-footer {
      padding-bottom: $gutter-xl;
    }
  }

  &__header {
    @include z-index('drawer', 'header');

    align-items: center;
    background-image: linear-gradient($_gradient-upper-controls);
    display: flex;
    flex: 0 0 auto;
    height: $height-drawer-upper-controls;
    justify-content: space-between;
    left: $gutter-xl;
    max-width: 100%;
    position: absolute;
    top: 0.1rem;
    width: calc(100% - #{$gutter-xl} * 2);

    #{$_this}__header-btn {
      @include drawer-header-btn;
    }
  }

  &__star-icon {
    &--is-watched {
      fill: $_color-star-is-watched;
    }

    &--not-watched {
      fill: $_color-star-not-watched;
    }
  }

  &__section {
    margin: $gutter-xl;

    &:first-of-type {
      margin-top: 0;
    }

    &:not(:first-of-type) {
      border-top: 0.1rem solid $color-drawer-border;
      padding-top: $gutter-l;
    }
  }

  &__footer {
    background-color: $_color-bg;
    box-sizing: border-box;
    padding: $gutter-xl;
    width: 100%;
  }

  &__footer-btn {
    width: 100%;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &--hidden {
    transform: translate(calc(100% + #{$gutter-xl}));
    transition:
      transform $transition-slow,
      visibility $transition-slow;
    visibility: hidden;
  }

  &.comment-builder-drawer,
  &.tp-response-drawer,
  &.qc-flag-drawer,
  &.thematic-flags-drawer,
  &.peer-review-drawer {
    width: 48rem;

    #{$_this}__footer {
      border-top: $border-width-s solid $color-drawer-border;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
