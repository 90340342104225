@import 'site-styles';

html {
  /* 1rem = 10px */
  font-size: $font-size-base-percentage;
  overflow-y: visible !important; // https://github.com/airbnb/react-dates/issues/1357
}

body {
  font-size: $font-size-base;
}

html,
body {
  background-color: $color-body-bg;
  color: $color-body;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

/* stylelint-disable selector-max-id */
#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
/* stylelint-enable selector-max-id */

.react-select-portal {
  > div {
    @include z-index('modal', 'portal');
  }
  // prevent additional scrolls on body if part of select menu is outside view
  .table-multi-select__menu-portal,
  .table-single-select__menu-portal {
    position: fixed;
  }
}
