@import 'src/scss/base/index';

// Created new catalog base for now so would not have to spend time on it, will create separate task to tweak the old one and fix places that needs to be fixed
@mixin filter-padding-reset {
  .ag-header-cell {
    &:nth-of-type(2) {
      padding: 0;
    }
  }
}

.catalog-base-new {
  $_this: &;
  $_color-filter-disabled-bg: $color-blue-800;
  $_color-odd-row-bg: $color-blue-800;
  $_height-table-header: 9rem;

  @include ag-grid-pinned-left-header;

  height: 100%;
  margin-bottom: $_height-table-header;
  overflow-y: auto;
  position: relative;
  top: $_height-table-header;
  width: calc(100% + #{$gutter-page} * 2);

  &__checkbox-cell,
  &__controls-cell {
    &.ag-cell-focus {
      border-color: transparent !important;
    }
  }

  &__checkbox-cell,
  &__checkbox-header-cell,
  &__controls-header-cell {
    @include z-index('ag-grid', 'action-cell');
  }

  &__checkbox-cell {
    @include ag-grid-checkbox-focus;
  }

  &__checkbox-header-cell {
    @include ag-grid-checkbox-focus;

    &::after {
      content: none !important;
    }
  }

  &__controls-cell,
  &__status-cell {
    .ag-cell-value {
      @include catalog-cell-align-center;
    }
  }

  &__controls-cell {
    @include z-index('catalog-table', 'controls-cell');

    &:focus,
    &:hover {
      @include z-index('catalog-table', 'controls-cell-focus');
    }

    .ag-cell-value {
      overflow: visible;
    }

    &:focus {
      .btn-icon {
        @include button-icon-color-bg;
      }
    }
  }

  &__controls-header-cell {
    &::after {
      content: none !important;
    }
  }

  &--disabled-header {
    .ag-header {
      pointer-events: none;
    }

    .table-multi-select,
    .table-single-select {
      &__control {
        background-color: $_color-filter-disabled-bg;
      }
    }

    .table-search-filter {
      &__input {
        background-color: $_color-filter-disabled-bg;
      }
    }
  }

  @include table-body-horizontal-scroll;

  .ag-react-container {
    width: 100%;
  }

  .ag-cell {
    font-family: $font-secondary;
    font-size: $font-size-m;

    &#{$_this}__controls-cell {
      border-color: transparent !important;
      padding: 0;
    }

    &#{$_this}__name-cell {
      font-family: $font-primary;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;

      &:not(.ag-cell-focus) {
        border-right: 0 !important;
      }
    }
  }

  .ag-header,
  .ag-header-container,
  .ag-header-row,
  .ag-header-viewport,
  .ag-pinned-left-header {
    overflow: visible;
  }

  .ag-header {
    @include z-index('ag-grid', 'header-w-filters');

    border-bottom: 0 !important;
    height: $_height-table-header !important;
    min-height: $_height-table-header !important;
    position: fixed;
    transform: translateY(-$_height-table-header);
  }

  .ag-header-cell {
    &#{$_this}__controls-header-cell,
    &#{$_this}__name-header-cell {
      padding-left: 0;
    }
  }

  .ag-header-row-column {
    .ag-react-container {
      align-items: center;
      display: flex;
    }
  }

  .ag-header-container {
    @include z-index('ag-grid', 'header-container');
  }

  .ag-header-row {
    border-bottom: $border-width-s solid $color-table-border-secondary;
  }

  .ag-layout-auto-height {
    overflow: visible !important;
  }

  .ag-overlay-no-rows-center {
    position: relative;
    top: -3.6rem;
  }

  .ag-pinned-left-cols-container {
    border-right: $border-width-s solid $color-table-border-primary;
  }

  .ag-pinned-left-header {
    @include floating-filters-z-index;

    .ag-header-row-floating-filter {
      @include filter-padding-reset;
    }

    .ag-header-cell {
      &::after {
        content: none;
      }
    }

    .ag-header-row {
      // Fix tooltip behind the filter in Safari
      &:first-of-type {
        @include z-index('catalog-table', 'safari-fix');
      }
    }
  }

  .ag-row {
    &.ag-row-hover {
      background-color: $color-table-row-hover !important;
      cursor: pointer;
    }

    &.ag-row-odd {
      background-color: $_color-odd-row-bg;
    }

    &.ag-row-selected {
      background-color: $color-table-row-selected;
    }
  }

  .ag-selection-checkbox {
    margin: 0 !important;
  }

  .table-multi-select {
    .option {
      &__icon {
        padding: 0;
      }
    }
  }

  > div {
    &:first-child {
      height: auto !important;
    }
  }
}
