$_color-dropdown-bg: $color-white;
$_color-dropdown-item-bg: $color-black-100;
$_color-dropdown-item-bg-focus: $color-black-200;
$_color-dropdown-item-bg-active: $color-black-250;
$_color-dropdown-item-bg-selected: $color-accent-blue-100-20;
$_color-dropdown-checkbox: $color-blue-600;
$_color-dropdown-checkbox-bg-focus: $color-black-200;

$_gutter-dropdown: 0.8rem;
$_gutter-dropdown-item-vertical: 0.8rem;
$_gutter-dropdown-item-horizontal: 1rem;

@mixin dropdown-menu($is-filter: false, $right-aligned: false) {
  background-color: $_color-dropdown-bg;
  border-radius: $border-radius-base;
  margin: 0.4rem 0 0;
  max-width: 20rem;
  opacity: 0;
  padding: $_gutter-dropdown 0;
  position: absolute;
  top: 100%;
  transform: scale(0);
  transform-origin: left top;
  transition:
    transform $transition-base,
    opacity $transition-quick,
    visibility $transition-base;
  visibility: hidden;
  width: 100%;

  @if $is-filter {
    min-width: 100%;
    transform: none;
    transition:
      opacity $transition-quick,
      visibility $transition-base;
    width: auto;
  }
}

@mixin dropdown-menu-right-aligned {
  right: 0;
  transform-origin: right top;
}

@mixin dropdown-menu-open {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}

@mixin dropdown-menu-item {
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  color: $color-text-light-bg;
  display: block;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  line-height: 1.5;
  padding: $gutter-base $gutter-l $gutter-base 1rem;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

@mixin dropdown-menu-item-focus {
  background-color: $_color-dropdown-item-bg-focus;
  cursor: pointer;
  outline: 0;
}

@mixin dropdown-menu-item-active {
  background-color: $_color-dropdown-item-bg-active;
}

@mixin dropdown-menu-item-selected {
  background-color: $_color-dropdown-item-bg-selected;
}

// Only for dropdowns inside ag-grid table
@keyframes open-table-dropdown-menu {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
