@import 'src/scss/base/index';

.upload-file {
  align-items: center;
  border: 0.2rem dashed $color-file-transfer-border;
  border-radius: $border-radius-base;
  display: flex;
  height: 19.2rem;
  justify-content: center;
  margin-top: $gutter-base;
  outline: none;
  text-align: center;
  transition: border-color $transition-base;

  &:focus,
  &:hover {
    border-color: $color-file-transfer-border-focus;
    cursor: pointer;
  }

  &__text {
    @include file-transfer-text;

    &--secondary {
      @include file-transfer-text-secondary;
    }
  }

  &__validation-error {
    color: $color-text-error;
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    margin: 0 0 0 $gutter-s;
  }

  &__validation-error-icon {
    flex-shrink: 0;
  }

  &__validation-error-wrapper {
    align-items: center;
    display: flex;
    margin: $gutter-base 0;
  }
}
