$_width-icons: 6.2rem;

@mixin ag-grid-pinned-left-header {
  .ag-pinned-left-header {
    @include z-index('ag-grid', 'pinned-header');

    background-color: $color-body-bg;
  }
}

@mixin ag-grid-checkbox-focus {
  &:focus {
    border-color: transparent !important;

    .ag-checkbox-input-wrapper {
      &::before {
        opacity: 1;
      }
    }
  }
}

@mixin catalog-cell-align-center {
  display: flex;
  height: 100%;
}

@mixin catalog-name-cell-size($is-pinned: false) {
  left: 0 !important;
  padding-left: $gutter-xl;
  width: 100% !important;

  @if $is-pinned {
    left: 0 !important;
    width: 35.4rem !important;
  }
}

@mixin catalog-name-cell-text {
  .ag-cell-value,
  .ag-header-cell-label {
    margin-left: $_width-icons;
  }
}

@mixin catalog-name-search-filter($remove-width: $_width-icons) {
  margin-left: auto;
  width: calc(100% - #{$remove-width});
  flex: none !important;
}

@mixin first-floating-filter($large-gutter: false) {
  .ag-header-row-floating-filter {
    .ag-header-cell {
      &:first-of-type {
        padding-right: 0;

        @if $large-gutter {
          padding-left: $gutter-xxl;
        }
      }
    }
  }
}

@mixin floating-filters-z-index {
  .ag-header-row-floating-filter {
    .ag-header-cell {
      &:first-of-type {
        @include z-index('ag-grid', 'floating-filter-cell-2');
      }

      &:nth-of-type(2) {
        @include z-index('ag-grid', 'floating-filter-cell');
      }

      &:nth-of-type(3) {
        @include z-index('ag-grid', 'floating-filter-cell-0');
      }
    }
  }
}

@mixin table-body-horizontal-scroll {
  .ag-root {
    &-wrapper,
    & {
      overflow: visible;
    }
  }

  .ag-body-horizontal-scroll {
    @include z-index('ag-grid', 'horizontal-scrollbar');

    .ag-horizontal-left-spacer {
      visibility: hidden;
    }

    bottom: 0;
    left: 0;
    position: sticky;
    right: 0;
  }
}

@mixin detail-row {
  background-color: $color-black;

  .ag-details-grid {
    border: solid $color-gray-50 1px;
    border-radius: $border-radius-l;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;

    .ag-cell {
      font-family: $font-primary;
    }

    .ag-header {
      background-color: $color-blue-800;
      height: 4.1rem !important;
    }

    .ag-react-container {
      height: 100%;
    }

    .ag-overlay-wrapper {
      padding-top: 6rem;
    }

    &--loading,
    &--no-rows {
      .ag-center-cols-clipper {
        min-height: 8.4rem !important;
      }
    }
  }
}

// Custom header filter styling to adjust styling due to ag date picker and default multi-filter being used.  Also styles the ag filter popup and child components.
@mixin ag-header-filter-custom-styling {
  .ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child {
    .ag-set-filter-list {
      max-height: 100% !important;
    }
  }

  .ag-picker-field-icon {
    .ag-icon-small-down {
      color: $color-black !important;
    }
  }

  .ag-multi-filter {
    .ag-filter-wrapper {
      display: none;
    }

    .ag-filter-wrapper ~ .ag-filter-wrapper {
      display: block;
    }

    .ag-virtual-list-viewport {
      width: 98% !important;
    }

    .ag-checked {
      fill: #2d3651;
    }

    .ag-checkbox-input-wrapper.ag-checked::before {
      color: #2d3651 !important;
      background-color: rgba(6, 8, 12, 0.1) !important;
    }

    .ag-checkbox-input-wrapper {
      border: 0.1rem solid $color-filter-background-grey !important;
    }
  }

  .ag-header-row-column-filter {
    > * {
      border-top: 0 !important;
    }

    border-top: var(--ag-borders-critical) var(--ag-border-color);

    height: 40px !important;
  }

  .ag-header-cell::before {
    display: none;
  }

  .ag-popup-child {
    color: $color-black !important;
    background-color: $color-white !important;
    border-radius: 0.8rem !important;

    .ag-icon-filter {
      color: $color-black !important;
    }

    .ag-menu-header,
    .ag-text-field-input {
      background-color: $color-white !important;
    }

    .ag-simple-filter-body-wrapper {
      width: 96%;
      scrollbar-color: $color-filter-background-grey $color-gray-100;
    }

    .ag-picker-field-wrapper {
      background-color: $color-black-100 !important;

      * {
        background-color: transparent !important;
      }
    }

    .ag-filter-date-from,
    .ag-filter-date-to {
      border: $color-filter-background-grey 2px solid;
      border-radius: 5px;
      padding: 2px;
    }

    button {
      background-color: $color-filter-background-grey !important;
      color: $color-black;
      align-items: center;
      border: 0.1rem transparent solid;
      border-radius: 0.8rem;
      box-sizing: border-box;
      display: flex;
      font-weight: 500;
      height: 3.2rem;
      justify-content: center;
      min-width: 7.2rem;
      outline: 0;
      padding: 0 1.2rem;
      text-decoration: none;
      transition:
        box-shadow 0.2s,
        background-color 0.2s,
        color 0.2s,
        opacity 0.2s;
    }

    button:hover {
      border-color: transparent !important;
      box-shadow: 0 0 0 0.4rem $color-gray-300-30;
      cursor: pointer;
    }

    .ag-select-list-item:hover {
      background-color: $color-filter-background-grey !important;
      cursor: pointer;

      * {
        background-color: transparent !important;
      }
    }
  }
}

// Styles the date picker and multi filter in the header, but not the popup.  The popup is styled in ag-header-filter-custom-styling.
@mixin ag-header-filter-custom-inline-styling($nthchild) {
  .ag-header-row-column-filter {
    > :nth-child(#{$nthchild}) {
      border-radius: 0.8rem;
      box-sizing: border-box;
      color: $color-white-500;
      font-size: 1.4rem;
      font-weight: 400;
      padding: 5px;
      margin-top: 3px;
      margin-left: 10px;
      margin-right: 10px;
      height: 3.2rem;
      align-items: center;
      justify-content: space-between;
      transition:
        background-color 0.2s linear,
        box-shadow 0.1s linear;
      background: transparent;
      background-color: $color-blue-700;
      width: 155px !important;

      * {
        background-color: $color-blue-700;
        transition:
          background-color 0.2s linear,
          box-shadow 0.1s linear;
      }
    }

    > :nth-child(#{$nthchild}):hover {
      background-color: $color-blue-500 !important;

      * {
        background-color: $color-blue-500 !important;
      }
    }
  }
}
