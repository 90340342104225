@import 'src/scss/base/index';

.assessment-overview {
  $_color-bg: $color-blue-700;
  $_color-content-shadow: $color-black-250;

  background-color: $_color-bg;
  border-radius: $border-radius-base;
  box-shadow: 0 0.4rem 0.4rem $_color-content-shadow;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: $gutter-xl 0;
  padding: $gutter-l;
  width: 100%;

  &__title {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    margin-bottom: $gutter-l;
  }
}
