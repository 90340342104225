@import 'src/scss/base/index';

.formik-input {
  $_color-text-modal-input: $color-blue-900-60;

  border: 0;
  border-radius: 0.8rem;
  color: $_color-text-modal-input;

  &.input {
    margin-bottom: 0;
  }
}
