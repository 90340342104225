@import 'src/scss/base/index';

.hamburger {
  $_color-btn-bg: $color-blue-400;
  $_color-btn: $color-white-200;
  $_color-btn-focus: $color-white;

  $_gutter-btn: 1rem;

  align-items: center;
  display: flex;
  left: -$_gutter-btn;
  position: relative;

  &__btn {
    background-color: transparent;
    border: 0;
    border-radius: $border-radius-base;
    fill: $_color-btn;
    outline: none;
    padding: $gutter-base $_gutter-btn;
    transition:
      background-color $transition-base,
      fill $transition-base;

    &:focus,
    &:hover,
    &--active {
      background-color: $_color-btn-bg;
      cursor: pointer;
      fill: $_color-btn-focus;
    }
  }
}
