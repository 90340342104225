@use 'sass:map';

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map.get($map, $key);
  }

  @return $map;
}

@function z-index($component, $item) {
  @return map-deep-get($z-indexes, $component, $item);
}

@mixin z-index($component, $item) {
  z-index: z-index($component, $item); /* stylelint-disable-line */
}
