@import 'src/scss/base/index';

.admin-card {
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    text-align: left;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    color: $color-white;
    margin: 0;
  }

  &__description {
    color: $color-white-300;
  }

  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__file-upload {
    color: $color-white;
  }
}
