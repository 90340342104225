@import 'src/scss/base/index';

.purchase-assessment-modal {
  $_header-color-text: $color-blue-600;

  .ts-modal__header-content {
    color: $_header-color-text;
  }

  .ts-modal__body {
    height: 29rem;
    max-height: 29rem;
    overflow-y: auto;
    padding-bottom: 0;
  }
}
