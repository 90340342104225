@import 'src/scss/base/index';

.purchase-modal-header {
  $_color-select-all-products-type: $color-blue-500;
  $_color-dropdown-box-shadow: $color-black-250;
  $_color-dropdown-border: $color-blue-900-15;
  $_color-dropdown-main-text: $color-blue-900-90;
  $_color-dropdown-secondary-text: $color-blue-900-70;

  $_this: &;

  &__set-all-products-types {
    margin: $gutter-m 0 $gutter-base;
    position: relative;

    .dropdown-menu {
      border: $border-width-s solid $_color-dropdown-border;
      box-shadow: 0 0.4rem 1.2rem $_color-dropdown-box-shadow;
      max-width: 28.1rem;
      width: 28.1rem;
    }

    #{$_this}__dropdown-text {
      &--main {
        color: $_color-dropdown-main-text;
        font-weight: $font-weight-bold;
        letter-spacing: 0.01rem;
        line-height: 1.15;
        margin-bottom: $gutter-base;
      }

      &--secondary {
        color: $_color-dropdown-secondary-text;
        font-size: $font-size-s;
        line-height: 1.15;
      }
    }

    .btn-link {
      &--secondary {
        &::after {
          background-color: $_color-select-all-products-type;
        }
      }

      &__text {
        color: $_color-select-all-products-type;
        font-weight: $font-weight-bold;
      }

      &__icon {
        fill: $_color-select-all-products-type;
        height: 1.6rem;
        margin-right: $gutter-base;
        width: 1.6rem;
      }
    }
  }
}
