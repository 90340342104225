@import 'src/scss/base/index';

.assessment-details {
  $_color-escalation-bg: $color-accent-red-100-15;

  @include drawer-details;

  $_this: &;

  &__label {
    @include drawer-details-label;

    &--heavy {
      color: $color-text-light-bg;
      font-weight: $font-weight-bold;
      margin: 0;
      text-transform: uppercase;
    }
  }

  &__escalation-wrapper {
    background-color: $_color-escalation-bg;
    border-radius: $border-radius-base;
    box-sizing: border-box;
    font-size: $font-size-s;
    padding: $gutter-m;
    margin: {
      bottom: -$gutter-base;
      top: $gutter-l;
    }
  }

  &__title {
    &:not(:first-of-type) {
      @include drawer-details-separator;
    }
  }

  &__description {
    white-space: pre-line;
    word-wrap: break-word;
  }
}
