@import 'src/scss/base/index';

.assessment-section {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-controls {
    align-items: center;
    display: flex;

    button {
      white-space: nowrap;
    }

    .btn {
      margin-left: 1.6rem;

      &__icon {
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  }

  &__description {
    color: $color-text-dark-bg-200;
    max-width: 120rem;
  }

  &__title {
    margin: $gutter-l 0 $gutter-base;
  }

  @include table-body-horizontal-scroll;
}
