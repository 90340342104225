.cookie-policy-modal-controller {
  .ts-modal {
    &__body {
      padding: 0;
    }

    &__footer-main-row {
      justify-content: center;
      margin-left: 0;
    }
  }
}
