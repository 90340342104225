@mixin button-icon-color-bg($light-bg: false) {
  $_color-bg: $color-white-800;
  $_color-bg-light: $color-black-100;

  background-color: $_color-bg;

  @if $light-bg {
    background-color: $_color-bg-light;
  }
}

@mixin button-icon-header {
  fill: $color-white-200;
}

@mixin button-icon-header-focus {
  fill: $color-white;
}

@mixin button-icon-header-active {
  background-color: $color-blue-400;
}
