@import 'src/scss/base/index';

.main-layout {
  $_this: &;
  $_height-header: 7.2rem;
  $_height-session-timer: 5.4rem;

  height: 100%;

  &__frame {
    @include frame;
  }

  &__controls:not(:empty) {
    display: flex;
    justify-content: space-between;
    margin-bottom: $gutter-l;
  }

  &.hide-overflow {
    overflow: hidden;

    #{$_this}__frame {
      height: calc(100% - #{$_height-header});
    }

    &--visible-session-timer {
      #{$_this}__frame {
        height: calc(100% - #{$_height-header} - #{$_height-session-timer});
      }
    }
  }

  &__toasts {
    @include z-index('toasts', 'container');

    align-items: center;
    display: flex;
    flex-direction: column;
    left: 50%;
    position: fixed;
    top: $gutter-l;
    transform: translateX(-50%);
  }
}
