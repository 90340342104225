@import 'src/scss/base/index';

.toast {
  $_color-toast-from: $color-gray-100;
  $_color-toast-from-error: $color-accent-red-100;
  $_color-toast-to: $color-white;
  $_color-toast-to-error: $color-accent-red-50;
  $_color-btn-bg-focus: $color-black-100;
  $_color-box-shadow: $color-black-600;

  $_gutter-bottom: 4rem;
  $_width-toast: 40rem;

  $_this: &;
  animation:
    showToast $transition-base forwards,
    progress $transition-toast forwards linear $transition-base;
  background-image: linear-gradient(
    to right,
    $_color-toast-to 50%,
    $_color-toast-from 50%
  );
  background-position: right;
  background-size: 200% 100%;
  border-radius: $border-radius-base;
  bottom: $_gutter-bottom;
  box-shadow: 0 0.8rem 1.6rem 0 $_color-box-shadow;
  box-sizing: border-box;
  color: $color-text-light-bg;
  display: flex;
  justify-content: space-between;
  opacity: 1;
  transition: opacity $transition-base;
  width: 40rem;

  &:not(:first-of-type) {
    margin-top: $gutter-base;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $gutter-m $gutter-m 1.8rem 4.8rem;
    position: relative;
    width: 100%;
  }

  &__btn-icon {
    align-self: flex-start;
    height: auto;
    opacity: 0.7;
    padding: 0;
    width: auto;

    &:focus,
    &:hover {
      opacity: 1;
    }
  }

  &__controls {
    display: flex;
    flex-shrink: 0;

    #{$_this}__btn {
      background-color: transparent;
      box-shadow: none;
      color: $color-text-light-bg-200;
      margin-right: $gutter-base;

      &:focus,
      &:hover {
        background-color: $_color-btn-bg-focus;
        color: $color-text-light-bg;
      }

      &:active {
        background-color: inherit;
      }
    }
  }

  &__description {
    flex-grow: 1;
    margin-right: 2rem;
    padding-top: $gutter-base;

    &--no-padding-top {
      padding-top: 0;
    }

    span {
      word-break: break-all;
    }
  }

  &__icon {
    fill: $color-text-light-bg;
    flex-shrink: 0;
    left: 1.4rem;
    position: absolute;
    top: 1.6rem;

    &--envelope {
      left: 1.8rem;
      top: 2rem;
    }

    &--user {
      height: 2rem;
      left: 1.8rem;
      top: 2.2rem;
      width: 2rem;
    }
  }

  &--is-hidden {
    opacity: 0;
  }

  &--is-paused {
    animation-play-state: running, paused;
  }

  &--error {
    background-image: linear-gradient(
      to right,
      $_color-toast-to-error 50%,
      $_color-toast-from-error 50%
    );

    #{$_this}__icon {
      stroke: $color-text-light-bg;
    }
  }

  &--auto-width {
    width: auto;
  }

  @keyframes showToast {
    0% {
      transform: translateY(-6rem);
    }

    100% {
      transform: none;
    }
  }

  @keyframes progress {
    0% {
      background-position: right;
    }

    100% {
      background-position: left;
    }
  }
}
