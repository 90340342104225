@import 'src/scss/base/index';

.dropdown-menu {
  @include dropdown-menu;
  @include z-index('dropdown', 'dropdown-menu');

  &.is-open {
    @include dropdown-menu-open;
  }

  &__content {
    margin: 0;
    padding: 0;
  }

  &__item {
    @include dropdown-menu-item;

    &:focus,
    &:hover {
      @include dropdown-menu-item-focus;
    }

    &:active {
      @include dropdown-menu-item-active;
    }

    &--selected {
      @include dropdown-menu-item-selected;
    }
  }

  &__item-wrapper {
    list-style: none;
  }
}
